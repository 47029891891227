import Utils from "../../utils";
import React from "react";
import T from "i18n-react";
import Elements from "./";
import MasonryInfiniteScroller from "react-masonry-infinite";
import logo_simple from "../../assets/img/logo_simple.svg";

export default class SearchResultList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            hasMore: true
        };
        this.count = 0;
        this.loading = false;
        this.page = 1;
        this.loadMore = this.loadMore.bind(this);
        this.setData = this.setData.bind(this);

        this.dismissListener = function (dismiss) {
            let data = this.state.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].data.dismiss === dismiss) {
                    data.splice(i, 1);
                    this.setState({data: data});
                    this.refs.masonry.forcePack();
                    break;
                }
            }
        };
    }

    setData(data) {
        this.count = 0;
        this.page = 1;
        this.setState({data: data, hasMore: true});
        this.refs.masonry.forcePack();
    }

    componentDidMount() {
        let _this = this;
        this.oldwindowresize = window.onresize;
        window.onresize = function () {
            _this.refs.masonry.forcePack();
        };
    }

    componentWillUnmount() {
        window.onresize = this.oldwindowresize;
    }

    loadMore(page) {
        if (this.loading || !this.state.data)
            return;
        this.loading = true;
        let _this = this;

        this.props.getMore(_this.page++, function (data) {
            _this.loading = false;
            _this.setState({data: _this.state.data.concat(data), hasMore: data.length > 0});
            _this.refs.masonry.forcePack();
        });
    }

    render() {
        let sizes = [
            {columns: 1, gutter: 10},
            {mq: '960px', columns: 2, gutter: 10}
        ];

        return (
            <div className={"ResultList " + (this.state.data ? "" : "loading")}>
                {(<MasonryInfiniteScroller key={Utils.nextKey()} ref="masonry" sizes={sizes} hasMore={this.state.hasMore} loadMore={this.loadMore} packed={"packed-list"} loader={
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"/>
                        <div className="sk-cube2 sk-cube"/>
                        <div className="sk-cube4 sk-cube"/>
                        <div className="sk-cube3 sk-cube"/>
                    </div>}>
                    {this.state.data && this.state.data.map(item => {
                            return (<Elements.ResultListItem dismissListener={() => this.dismissListener(item.data.dismiss)} key={Utils.nextKey()} data={item}/>);
                        }
                    )} <React.Fragment/> </MasonryInfiniteScroller>)}
                {this.state.data && this.state.data.length === 0 && <div style={{padding: 20, textAlign: "center"}}>
                    <img src={logo_simple} width={150} style={{opacity: 0.3, marginTop: 30}}/>
                    {(typeof this.props.placeholder === 'undefined' || typeof this.props.placeholder === 'string') ?
                        (<h1 className={"placeholder"}>{this.props.placeholder ? this.props.placeholder : T.translate("label-search_placeholder_no_results")}</h1>)
                        : this.props.placeholder}
                </div>
                }
            </div>
        );
    }
}