import React from 'react';
import Utils from "../../utils";
import ConversationsBar from "../sections/ConversationsBar";
import Conversation from "../sections/Conversation";
import T from "i18n-react/dist/i18n-react";
import {confirmAlert} from "react-confirm-alert";
import Parser from "html-react-parser";
import UserSearch from "../elements/userSearch";
import logo_simple from "../../assets/img/logo_simple.svg";

class Chat extends React.Component {
    constructor(props) {
        super(props);
        let _this = this;

        this.openConversation = this.openConversation.bind(this);
        this.newMessage = this.newMessage.bind(this);

        this.conversationBar = <ConversationsBar ref={(ref) => this.conversationBarRef = ref} default_conversation={Utils.getCurrentPage().parameters.id_conversation} endpoint={this.props.endpoint} onConversationSelect={_this.openConversation} newConversation={_this.newConversation}/>;

        this.conversation = (<div className="Conversation Placeholder">
            <img src={logo_simple} width={150} style={{opacity: 0.3, marginTop: 30}}/>
            {<h1 className={"placeholder"}>{T.translate("label-conversation_placeholder")}</h1>}
        </div>);

        window.Chat = this;
    }

    openConversation(conversation) {
        window.history.replaceState(null, null, Utils.base_url + "messages/" + conversation['conversation.id']);
        this.conversation = <Conversation ref={(ref) => this.selectedConversation = ref} key={conversation['conversation.id']} endpoint={this.props.endpoint} {...conversation}/>;
        this.forceUpdate();
    }

    componentDidMount() {
        Utils.getHeader().closeHero();
    }

    newMessage(message) {
        message = message.data;
        try {
            switch (message.type) {
                case "new_message":
                    let conversation = this.conversationBarRef.getConversation(message.conversation);
                    if (conversation) {
                        if (conversation['conversation.id'] === this.selectedConversation.props['conversation.id']) {
                            this.selectedConversation.newMessage(message);
                        } else {
                            this.conversationBarRef.newMessageOther(message);
                        }
                    } else {
                        this.conversationBarRef.newConversationMessageOther(message.conversation);
                    }
                    break;
                case "conversation_seen":
                    this.conversationBarRef.conversationSeen(parseInt(message.conversation));
                    break;
                default:
                    return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    newConversation() {
        let user_search = null;
        confirmAlert({
            customUI: ({onClose}) => {
                let value = T.translate(prompt.default);
                return (
                    <div className={'container react-confirm-alert-body select-padding'}>
                        <h1>{T.translate("label-new_conversation")}</h1>
                        <p className={"formText"}>{Parser(T.translate("label-new_conversation_instructions"))}</p>
                        <UserSearch ref={(ref) => user_search = ref}/>
                        <div className="react-confirm-alert-button-group">
                            <a className={"Button margin special"} onClick={() => {
                                let selected_user = user_search.getSelected();
                                if (!selected_user) {
                                    Utils.error("label-new_conversation_user_required");
                                } else {
                                    Utils.post("chat/new_single/" + selected_user, null, (data) => {
                                        console.log(data);
                                    });
                                    onClose();
                                }
                            }}>{T.translate("label-start_conversation")}</a><a className={"Button margin"} onClick={onClose}>{T.translate("label-cancel")}</a>
                        </div>
                    </div>
                )
            }
        });
    }

    componentWillUnmount() {
        delete window.Chat;
    }

    render() {
        return (
            <div className={"Chat"}>
                <div>
                    {this.conversationBar} {this.conversation}
                    <div style={{clear: "both"}}/>
                </div>
            </div>
        );
    }
}

export default Chat;