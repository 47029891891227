import cx from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import range from 'lodash/range';
import chunk from 'lodash/chunk';
import InputSlider from 'react-input-slider';
import $ from "jquery";

class Time extends Component {
    changeHours = pos => {
        const m = this.props.moment;
        m.hours(pos.x);
        this.props.onChange(m);
    };

    changeMinutes = pos => {
        const m = this.props.moment;
        m.minutes(pos.x);
        this.props.onChange(m);
    };

    render() {
        const m = this.props.moment;

        return (
            <div className={cx('m-time', this.props.className)}>
                <div className="showtime">
                    <span className="time">{m.format('HH')}</span>
                    <span className="separater">:</span>
                    <span className="time">{m.format('mm')}</span>
                </div>

                <div className="sliders">
                    <div className="time-text">Hours:</div>
                    <InputSlider
                        className="u-slider-time"
                        xmin={0}
                        xmax={23}
                        xstep={this.props.hourStep}
                        x={m.hour()}
                        onChange={this.changeHours}
                    />
                    <div className="time-text">Minutes:</div>
                    <InputSlider
                        className="u-slider-time"
                        xmin={0}
                        xmax={59}
                        xstep={this.props.minStep}
                        x={m.minute()}
                        onChange={this.changeMinutes}
                    />
                </div>
            </div>
        );
    }
}

const Day = ({ i, w, d, className, ...props }) => {
    const prevMonth = w === 0 && i > 7;
    const nextMonth = w >= 4 && i <= 14;
    const cls = cx({
        'prev-month': prevMonth,
        'next-month': nextMonth,
        'current-day': !prevMonth && !nextMonth && i === d
    });

    return <td className={cls} {...props}>{i}</td>;
};

class Calendar extends Component {
    selectDate = (i, w) => {
        const prevMonth = w === 0 && i > 7;
        const nextMonth = w >= 4 && i <= 14;
        const m = this.props.moment;

        if (prevMonth) m.subtract(1, 'month');
        if (nextMonth) m.add(1, 'month');

        m.date(i);

        this.props.onChange(m);
    };

    prevMonth = (e) => {
        e.preventDefault();
        this.props.onChange(this.props.moment.subtract(1, 'month'));
    };

    nextMonth = (e) => {
        e.preventDefault();
        this.props.onChange(this.props.moment.add(1, 'month'));
    };

    render() {
        const m = this.props.moment;
        const d = m.date();
        const d1 = m.clone().subtract(1, 'month').endOf('month').date();
        const d2 = m.clone().date(1).day();
        const d3 = m.clone().endOf('month').date();
        const days = [].concat(
            range(d1 - d2 + 1, d1 + 1),
            range(1, d3 + 1),
            range(1, 42 - d3 - d2 + 1)
        );
        const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        return (
            <div className={cx('m-calendar', this.props.className)}>
                <div className="toolbar">
                    <button type="button" className="prev-month" onClick={(e)=>this.prevMonth(e)}>
                        <i className={this.props.prevMonthIcon} />
                    </button>
                    <span className="current-date">{m.format('MMMM YYYY')}</span>
                    <button type="button" className="next-month" onClick={(e)=>this.nextMonth(e)}>
                        <i className={this.props.nextMonthIcon} />
                    </button>
                </div>

                <table>
                    <thead>
                    <tr>
                        {weeks.map((w, i) => <td key={i}>{w}</td>)}
                    </tr>
                    </thead>

                    <tbody>
                    {chunk(days, 7).map((row, w) =>
                        <tr key={w}>
                            {row.map(i =>
                                <Day
                                    key={i}
                                    i={i}
                                    d={d}
                                    w={w}
                                    onClick={() => this.selectDate(i, w)}
                                />
                            )}
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default class InputMoment extends Component {
    static defaultProps = {
        prevMonthIcon: 'ion-ios-arrow-left',
        nextMonthIcon: 'ion-ios-arrow-right',
        minStep: 1,
        hourStep: 1
    };

    state = {
        tab: 0
    };

    handleClickTab = (e, tab) => {
        e.preventDefault();
        this.setState({ tab: tab });
    };

    handleSave = e => {
        e.preventDefault();
        if (this.props.onSave) this.props.onSave();
    };

    componentDidMount() {
        this.inputField = $(this.refs.inputMoment).prev(".input")[0];
    }

    render() {
        const { tab } = this.state;
        const {
            moment: m,
            className,
            prevMonthIcon,
            nextMonthIcon,
            minStep,
            hourStep,
            onSave,
            ...props
        } = this.props;
        const cls = cx('m-input-moment', className);

        return (
            <div className={cls} {...props} ref="inputMoment">
                <div className="options">
                    <button
                        type="button"
                        className={cx('ion-calendar im-btn', { 'is-active': tab === 0 })}
                        onClick={e => this.handleClickTab(e, 0)}
                    >
                        Date
                    </button>
                    <button
                        type="button"
                        className={cx('ion-clock im-btn', { 'is-active': tab === 1 })}
                        onClick={e => this.handleClickTab(e, 1)}
                    >
                        Time
                    </button>
                </div>

                <div className="tabs">
                    <Calendar
                        className={cx('tab', { 'is-active': tab === 0 })}
                        moment={m}
                        onChange={(e)=>{
                            this.props.onChange(e);
                            this.inputField.focus();
                        }}
                        prevMonthIcon={this.props.prevMonthIcon}
                        nextMonthIcon={this.props.nextMonthIcon}
                    />
                    <Time
                        className={cx('tab', { 'is-active': tab === 1 })}
                        moment={m}
                        minStep={this.props.minStep}
                        hourStep={this.props.hourStep}
                        onChange={this.props.onChange}
                    />
                </div>

                {this.props.onSave ? (
                    <button
                        type="button"
                        className="im-btn btn-save ion-checkmark"
                        onClick={this.handleSave}
                    >
                        Save
                    </button>
                ) : null}
            </div>
        );
    }
}