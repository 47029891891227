import React, {Component} from 'react';
import Header from "./sections/Header";
import Splashscreen from "./sections/Spashscreen";
import 'react-notifications/lib/notifications.css';
import "./external/input-moment.css";
import "../assets/styles/styles.css";
import "animate.css";
import Footer from "./sections/Footer";
import Utils from "../utils";
import Form from "./templates/Form";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Functions from "../functions";
import RegisterForm from "./elements/registerForm";
import NewsPopup from "./elements/news_popup";
import {withCookies} from "react-cookie";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            appBody: null,
            user: null,
            splashPage: null,
            fullscreen: false
        };

        window.App = this;

        this.setBody = this.setBody.bind(this);
        this.setUser = this.setUser.bind(this);
        this.setSplashPage = this.setSplashPage.bind(this);
        this.setFullscreen = this.setFullscreen.bind(this);
        Utils.notificationManager = NotificationManager;

        if (!window.sessionStorage.getItem("ca-pagestack"))
            window.sessionStorage.setItem("ca-pagestack", JSON.stringify([]));
    }

    setFullscreen(fullscreen) {
        this.setState({fullscreen: fullscreen});
    }

    componentDidMount() {
        let _this = this;

        Utils.initConfig(function () {
            //Callback
            setTimeout(function () {
                _this.setState({ready: true});
                Utils.openCurrentUrl();
            }, 100);

        });

        window.onpopstate = function (event) {
            Utils.openPage(event.state, false, true);
        };

        window.onbeforeunload = function () {
            return Utils.documentHasChanges() ? true : undefined; //return undefined for browser compatibility
        };
    }

    logout(dest) {
        Utils.post("logout", null, (data) => {
            Utils.setConfig(data.app_config);
            this.setUser(null);
            dest ? Utils.openUrl(dest) : Utils.openDefault();
        });
    }

    setUser(user) {
        this.setState({user: user});
        if (!Utils.isEmulating())
            if (user)
                Functions.centrifugePrepare();
            else
                Functions.centrifugeDestroy();
    }

    setBody(body) {
        this.setState({appBody: body, splashPage: null});
    }

    setSplashPage(page) {
        this.setState({splashPage: page});
    }

    showNewsPopup = (news_popup) => {
        if (!news_popup)
            return;
        if (this.props.cookies.get("NewsPopupDate") === news_popup.date)
            return;
        this.setState({news_popup});
    };

    render() {
        let _this = this;
        let app;
        if (!this.state.ready) {
            app = (<Splashscreen/>);
        } else {
            let wide = false;
            if (this.state.splashPage) {
                let splash_page = null;
                switch (this.state.splashPage) {
                    case "register":
                        wide = true;
                        if (this.refs.splash_form) {
                            //this.refs.splash_form.buildBody();
                        }
                        splash_page = (
                            <RegisterForm/>
                        );
                        break;
                    case "recover_password":
                        let recover_body = [
                            {type: "text", label: "label-recover_password_instructions"},
                            {type: "input:text", name: "email", label: "label-your_email"},
                            {type: "input:password", name: "new_password", label: "label-your_new_password"},
                            {type: "button:special", action: "submit:recover_password", label: "label-recover_password"},
                            {type: "button", action: "goto:login", label: "label-cancel"}
                        ];
                        if (this.refs.splash_form) {
                            this.refs.splash_form.buildBody({
                                title: "label-recover_password",
                                body: recover_body
                            });
                        }
                        splash_page = (
                            <Form enterSubmit ref="splash_form" key={Utils.nextKey()} attr={
                                {
                                    title: "label-recover_password",
                                    body: recover_body
                                }} onSuccess={function (data) {
                                Utils.success("label-recover_password_sent_email");
                            }}/>
                        );
                        break;
                    case "login":
                        let login_body = [
                            {type: "input:text", name: "email", label: "label-email"},
                            {type: "input:password", name: "password", label: "label-password"},
                            {type: "button:special", action: "submit:login", label: "label-login"},
                            {type: "button", action: "goto:recover_password", label: "label-forgot_password"},
                            {type: "button", action: "goto:register", label: "label-new_account"}
                        ];
                        if (this.refs.splash_form) {
                            this.refs.splash_form.buildBody({
                                title: "label-login",
                                body: login_body
                            });
                        }
                        splash_page = (
                            <Form enterSubmit ref="splash_form" key={Utils.nextKey()} attr={
                                {
                                    title: "label-login",
                                    body: login_body
                                }} onSuccess={function (data) {
                                Utils.setConfig(data.app_config);
                                _this.setUser(data.app_config.user);
                                Utils.registerFirebase();
                                if (window.location.pathname.replace(/^\/+/, "") !== "login")
                                    Utils.openCurrentUrl();
                                else
                                    Utils.openDefault();
                            }}/>
                        );
                        break;
                    default:
                        splash_page = (<span>what</span>);
                        break;
                }
                app = (
                    <Splashscreen boxWide={wide}>
                        {splash_page}
                    </Splashscreen>);
            } else {
                app = (<div id="wrapper" className="App">
                    <Header menuItems={Utils.getNavbar()} ref="header"/>
                    <div className={(this.state.fullscreen ? "" : "container") + " full_mobile"} ref="appBody">
                        {this.state.appBody}
                    </div>
                    <Footer links={Utils.getFooter()}/>
                </div>);
            }
        }
        return (<React.Fragment>
            {app}
            {this.state.news_popup && <NewsPopup {...this.state.news_popup} onClose={() => {
                this.props.cookies.set("NewsPopupDate", this.state.news_popup.date, {
                    expires: (d => new Date(d.getFullYear() + 10, d.getMonth(), d.getDate()))(new Date)
                });
                this.setState({news_popup: null});
            }}/>}
            <NotificationContainer/>
        </React.Fragment>);
    }
}

export default withCookies(App);