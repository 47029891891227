import React, {useState} from "react";
import Utils from "../../utils";
import T from "i18n-react";
import Elements from "./index";

function intersperse(arr, sep) {
    if (arr.length === 0) {
        return [];
    }

    return arr.slice(1).reduce(function (xs, x, i) {
        return xs.concat([sep, x]);
    }, [arr[0]]);
}

export default function NewsPopup(props) {
    let [closing, setClosing] = useState(false);
    let [showing, setShowing] = useState(false);

    if (!showing) {
        setTimeout(() => setShowing(true), 500);
        return null;
    }

    return <div className={"NewsPopup animated " + (closing ? "faster fadeOut" : "fadeIn")}>
        <div className={"NewsPopup_box container animated " + (closing ? "faster slideOutUp" : "slideInDown")}>
            <div className={"NewsPopup_image"} style={{backgroundImage: "url(" + Utils.getFullUrl(props.image) + ")"}}/>
            <div className={"NewsPopup_content"}>
                <h1>{props.title}</h1>
                <h4>{intersperse(props.text.split("\n"), <br/>)}</h4>
                <Elements.Button
                    label={T.translate("label-close")}
                    class={"special"}
                    style={{marginTop: 35}}
                    onClick={() => {
                        setClosing(true);
                        setTimeout(props.onClose, 1050);
                    }}
                />
            </div>
        </div>
    </div>;
}