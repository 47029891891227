import React from "react";
import {Icon} from "react-font-awesome-5";
import Utils from "../../utils";
import T from "i18n-react";
import Moment from "react-moment";
import ReactTooltip from "react-tooltip";
import Elements from "./";

export default function (props) {
    let item = props.bundle.data;
    let docPublished = item["document.published"];
    let docClosed = item["document.closed"];
    let RelIcon = Icon[docPublished ? (docClosed ? "CalendarTimes" : "Globe") : "PencilAlt"];
    if (!RelIcon) RelIcon = Icon.Circle;
    //let type = item["document.document_type_id_fk"] === "1" ? "challenge" : "dissertation";
    let description = Utils.parseJSON(item['document.description']);
    if (description) {
        let description_aux = '';
        for (let question in description) {
            try {
                if (description[question].trim() !== '')
                    description_aux += " - " + description[question];
            } catch (e) {
            }
        }
        description = description_aux.substring(3);
    } else {
        description = item['document.description'] || T.translate("label-no_description");
    }
    description = description.length > 256 ? description.substring(0, 253) + "..." : description;
    let user_fullname = "";
    if (item['user.name'] && item['user.surname'])
        user_fullname = item['user.name'].trim().split(" ")[0] + " " + item['user.surname'].trim().split(" ").reverse()[0];
    let corner;
    let box;
    if (item.dismiss)
        corner = (<span data-tip={T.translate("label-dismiss")} className="icon" onClick={function (e) {
            e.preventDefault();
            e.stopPropagation();
            //Dismiss
            Utils.post(item.dismiss, {}, () => {
                Utils.success(T.translate("label-dismiss_success"));
                if (props.dismissListener)
                    props.dismissListener();
            });
        }}><Icon.Times/></span>);
    else
        corner = (<span data-tip={T.translate("label-" + (docPublished ? (!docClosed ? "published" : "closed") : "draft"))} className="icon"><RelIcon/></span>);
    box = (<Elements.Box>
        <div className="DocumentListItem">
            <div className="header">

                <a onClick={(e) => e.preventDefault()} href={item["destination"] && (Utils.base_url + item["destination"])}>
                    <h3 className="clickable" onClick={Utils.click(item["destination"])}>
                        {item['document.title']} {corner}
                    </h3>
                </a>

                <h4><Moment format="MMMM Do, YYYY">{item["document.published"] || item["document.creation_date"]}</Moment>
                </h4>
            </div>
            <p>{description}</p>{!(props.bundle.custom && props.bundle.custom.hide_user) &&
        <Elements.UserBox fullWidth name={user_fullname} user_id={item['user.id']} use_dark_text={true} organization={item["organization.name"]} image={"static/image/user/" + item['user.image']}/>} <ReactTooltip effect="solid" place="right"/>
        </div>
    </Elements.Box>);
    return box;
}