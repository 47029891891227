import logo from "../../assets/img/logo.svg";
import React from 'react';
import Menu from "./Menu";
import {Swipeable} from 'react-swipeable'
import Utils from "../../utils";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            heroContents: null,
            heroOpen: false,
            warningContents: null,
            warningOpen: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.swipingRight = this.swipingRight.bind(this);
        this.swipingLeft = this.swipingLeft.bind(this);
        this.openHero = this.openHero.bind(this);
        this.closeHero = this.closeHero.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.closeWarning = this.closeWarning.bind(this);

        Utils.setHeader(this);
    }

    componentDidMount() {
        window.onkeydown = this.listenForClose.bind(this);
    }

    listenForClose(e) {
        e = e || window.event;

        if (this.state.open && (e.key === 'Escape' || e.keyCode === 27)) {
            this.toggleMenu(e);
        }
    }

    swipingRight(e, absX) {
        if (!this.state.open)
            this.setState({open: true});
    }

    swipingLeft(e, absX) {
        if (this.state.open)
            this.setState({open: false});
    }

    render() {
        return (
            <div>
                <div onTouchStart={this.closeMenu} onClick={this.closeMenu}
                     className={"Header " + (this.state.open ? 'open' : '')}>
                    <div className="navbar">
                        <Burger handler={this.toggleMenu}/>
                        <div className="container">
                            <a onClick={(e) => e.preventDefault()} href={"/" + Utils.getDefaultUrl()}><img src={logo} onClick={Utils.openDefault} alt="Challenge Academy" style={{cursor: "pointer"}} className="logo"/></a>
                        </div>
                    </div>
                    <Swipeable
                        onSwipedRight={this.swipingRight}
                        onSwipedLeft={this.swipingLeft}
                        className="swipe">
                        <Menu items={this.props.menuItems}/>
                    </Swipeable>
                </div>
                <HeroTitle open={this.state.heroOpen}>
                    {this.state.heroContents}
                </HeroTitle>
                {this.state.warningOpen && this.state.warningContents}
            </div>
        );
    }

    toggleMenu(e) {
        e.stopPropagation();
        this.setState({open: !this.state.open});
    }

    closeMenu(e) {
        (!e || e.target.classList.contains('Header')) && this.setState({open: false});
    }

    openHero(contents) {
        this.setState({heroContents: contents, heroOpen: true});
    }

    closeHero() {
        this.setState({heroOpen: false});
    }

    showWarning(contents) {
        if (!this.state.warningOpen) {
            this.setState({
                warningContents: (
                    <div className="warning-hero"><h5>{contents}</h5></div>
                ), warningOpen: true
            });
        }
    }

    closeWarning() {
        this.setState({warningOpen: false});
    }
}

function Burger(props) {
    return (
        <div onClick={props.handler} className="burger hamburger hamburger--arrow">
            <div className="hamburger-box">
                <div className="hamburger-inner"></div>
            </div>
        </div>
    );
}

function HeroTitle(props) {
    return (
        <div className={"HeroTitle " + (props.open ? "open" : "closed")}>
            <div className="container">
                {props.children}
            </div>
        </div>
    );
}

export default Header;