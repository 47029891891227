import {Icon} from "react-font-awesome-5";
import React from "react";

export default function (props) {
    let RelIcon = props.icon && Icon[props.icon];
    return (
        <a
            onClick={props.onClick || function () {
                console.log("NO ACTION")
            }}
            className={"Button " + (props.class)}
            style={props.style}>
            {RelIcon && <RelIcon/>} {props.label}
        </a>
    );
}