import React from 'react';
import Utils from "../../utils";
import Elements from "../elements";
import T from 'i18n-react';
import {Icon} from "react-font-awesome-5";
import {confirmAlert} from "react-confirm-alert";

class Feed extends React.Component {
    constructor(props) {
        super(props);

        this.getMore = this.getMore.bind(this);
        this.setRef = this.setRef.bind(this);
        this.removeNote = this.removeNote.bind(this);
        this.updateNote = this.updateNote.bind(this);

        this.state = {
            pending: null,
            notes: null
        };

        this._refs = {};

        this.creatingNote = null;
        this.mounted = true;

        Utils.getHeader().openHero(<div>
            <h2> {T.translate("label-welcome") + " " + window.App.state.user.name.trim().split(" ")[0]} </h2>
            <h4 style={{"font-weight": "400"}}> {T.translate("label-feed_intro_" + (window.App.state.user.type).toLowerCase())} </h4>
        </div>);

        this.resultListComponent = (<Elements.SearchResultList placeholder={T.translate("label-feed_placeholder")} ref={(ref) => this.setRef("result_list", ref)} getMore={this.getMore}/>);

        this.pendingLabel = "label-pending_label_" + (window.App.state.user.type).toLowerCase();

        window.Feed = this;
    }

    NoteBox = class NoteBox extends React.Component {
        constructor(props) {
            super(props);

            this.title = props.title;
            this.content = props.content;
            this.changed = false;

            this.onChange = this.onChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
            this.textareaResize = this.textareaResize.bind(this);
            this.titleFocus = this.titleFocus.bind(this);
            this.setTableValue = this.setTableValue.bind(this);
        }

        setTableValue(table, value) {
            this[table] = value;
            this.forceUpdate();
        }

        onChange(event) {
            let targ = event.target;
            let _val = targ.value === "" ? null : targ.value;
            if (this[targ.name] !== _val) {
                this[targ.name] = _val;
                this.changed = true;
            }
        }

        onBlur(event) {
            if (this.changed) {
                let targ = event.target;
                let _val = this[targ.name];
                if (targ.name === "title" && (_val == null || _val.trim() === "")) {
                    _val = T.translate("label-note_untitled");
                    this[targ.name] = _val;
                    targ.value = _val;
                }
                Utils.post("note/update", {
                    id: this.props.noteId,
                    table_name: targ.name,
                    value: _val
                }, () => this.changed = false);
            }
        }

        textareaResize(event) {
            let element = event.target;
            if (element.scrollHeight > 80) {
                element.style.height = "auto";
                element.style.height = (element.scrollHeight + 10) + "px";
            }
        }

        titleFocus(event) {
            if (this.title === T.translate("label-note_untitled") || this.title === T.translate("label-new_note"))
                event.target.select();
        }

        componentDidMount() {
            if (this.refs.content) {
                this.textareaResize({target: this.refs.content});
            }
        }

        render() {
            let id = this.props.noteId;
            let _this = this;
            return (
                <Elements.Box key={Utils.nextKey()} title={(<div className={"Field"}>
                    <label> <input key={Utils.nextKey()} type="text" name="title" maxLength="32" defaultValue={this.title} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.titleFocus}/> </label>
                </div>)}>
                    <p key={Utils.nextKey()} className={"note-content"}>
                        <textarea key={Utils.nextKey()} ref="content" className={"NoteText"} name="content" placeholder={T.translate("label-note_content_placeholder")} defaultValue={this.content} onChange={this.onChange} onBlur={this.onBlur} onInput={this.textareaResize} rows="2"/> <Icon.TrashAlt className={"note-trash"} onClick={() => {
                        confirmAlert({
                            customUI: ({onClose}) => {
                                return (
                                    <div className='container react-confirm-alert-body'>
                                        <h1>{T.translate('label-confirmation')}</h1>
                                        <p>{T.translate("label-confirm_remove_note")}</p>
                                        <div className="react-confirm-alert-button-group">
                                            <a className={"Button margin special"} onClick={() => {
                                                Utils.post("note/delete", {id: id}, () => {
                                                    _this.props.parent.removeNote(id);
                                                });
                                                onClose()
                                            }}>{T.translate("label-yes")}</a> <a className={"Button margin"} onClick={onClose}>{T.translate("label-cancel")}</a>
                                        </div>
                                    </div>
                                )
                            }
                        })

                    }}></Icon.TrashAlt>
                    </p>
                </Elements.Box>
            );
        }
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    updateNote(message) {
        if (!this.mounted)
            return;
        let note = message.data;
        let id = note.id.toString();
        let This = this;
        switch (note.action) {
            case "create":
                let notes = This.state.notes;
                if (!this.creatingNote && !notes.find(function (_note) {
                    return _note.props.noteId === id;
                })) {
                    notes.push(<This.NoteBox ref={(ref) => This.setRef("note" + id, ref)} key={Utils.nextKey()} noteId={id} title={note.value} content={""} parent={This}/>);
                    This.setState({
                        notes: notes.slice() //this is here because of black magic
                    });
                }
                break;
            case "update":
                this._refs["note" + note.id].setTableValue(note.table, note.value.replace(/\\n/g, "\n"));
                break;
            case "delete":
                this.removeNote(id);
                break;
            default:
                break;
        }
    }

    removeNote(id) {
        let foundNote = this.state.notes.find(function (note) {
            return note.props.noteId === id;
        });
        if (foundNote) {
            let _notes = this.state.notes.slice();
            _notes.splice(_notes.indexOf(foundNote), 1);
            this.setState({notes: _notes});
        } else {
            console.log("not found");
        }
    }

    componentDidUpdate() {
        if (this.creatingNote) {
            this.creatingNote = null;
        }
    }


    componentDidMount() {
        let This = this;
        Utils.get(this.props.endpoint, {page: 0}, function (data) {
            This._refs.result_list.setData(data.feed);

            let notes = [], pending = [];
            data.notes.forEach(function (td) {
                notes.push(<This.NoteBox ref={(ref) => This.setRef("note" + td["user_note.id"], ref)} key={Utils.nextKey()} parent={This} noteId={td["user_note.id"]} title={td["user_note.title"]} content={td["user_note.content"]}/>);
            });

            data.pending.forEach(function (td) {
                let title = td['document.title'];
                let docURL = td['document_type.designation'].toLowerCase() + "/" + td['document.id'] + "/edit";
                pending.push(<p className={"truncate"} key={Utils.nextKey()}>
                    {"• "}<a className="clickable" onClick={() => Utils.openUrl(docURL)}>{title}</a>
                </p>);
            });
            This.setState({
                notes: notes,
                pending: pending
            });
        })
    }

    setRef(name, ref) {
        this._refs[name] = ref;
    }

    getMore(page, callback) {
        Utils.get(this.props.endpoint, {page: page}, function (data) {
            callback(data.feed);
        });
    }

    render() {
        let This = this;
        return (
            <div className={"Feed"}>
                <div className={"TodoList"}>
                    {(this.state.pending && this.state.pending.length > 0) ? (<Elements.Box title={T.translate("label-todo_list")}>
                        <div className={"TodoItem"}>
                            <span className={"filterLabel"}>{T.translate(this.pendingLabel)}</span> {this.state.pending}
                        </div>
                    </Elements.Box>) : undefined} {this.state.notes} <a key={Utils.nextKey()} className={"note-button" + (this.state.notes && this.state.notes.length > 0 ? "" : " no-notes")} onClick={() => {
                    let noteTitle = T.translate("label-new_note");
                    this.creatingNote = true;
                    Utils.post("note/new", {title: noteTitle}, (td) => {
                        let notes = This.state.notes;
                        notes.push(<This.NoteBox ref={(ref) => This.setRef("note" + td, ref)} key={Utils.nextKey()} noteId={td} title={noteTitle} content={""} parent={This}/>);
                        This.setState({
                            notes: notes.slice() //this is here because of black magic
                        });
                    });
                }}><Icon.Plus></Icon.Plus> {T.translate("label-new_note")}</a>
                </div>
                <div className={"searchResults"}>
                    {this.resultListComponent}
                </div>
                <div style={{clear: "both"}}/>
            </div>

        );
    }
}

export default Feed;