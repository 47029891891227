import T from "i18n-react";
import React from "react";
import Elements from "./";

export default function (props) {
    return (<div className="SpannedHeader">
        <Elements.Box>
            <h2>{T.translate(props.attr._title)}</h2>
        </Elements.Box>
    </div>);
}