import Utils from "../../utils";
import {Icon} from "react-font-awesome-5";
import React from "react";
import Elements from "./";

export default function (props) {
    let user_box_pointer = props.user_id ? {cursor: "pointer"} : {};
    let box = <div className={"UserBox" + (props.fullWidth ? " fullwidth" : "") + (props.selected ? " selected" : "")} style={user_box_pointer} onClick={props.onClick ? props.onClick : props.user_id && !props.newPage && !props.noAction ? () => Utils.openUrl("profile/" + props.user_id) : undefined}>
        <Elements.Image alt={props.name} style={Utils.parseStyles("padding: 0; height: 50px; float: left; marginRight: 8px; marginBottom: 0; borderRadius: 50%; width: 50px")} src={props.image + "_s"} default={'static/image/user/no_image'} use_background="cover"/>
        <p><span className={props.user_id && !props.noAction ? "clickable" + (props.use_dark_text ? "" : "-light") : ""}>{props.name} {props.newPage && <Icon.ExternalLinkAlt/>}<span>{props.organization}</span> {props.country && (<span>
                       <Icon.Globe/> {props.country}</span>)}</span>
        </p>
    </div>;
    if (!props.noAction) {
        box = <a target={props.newPage ? "_blank" : "_self"} onClick={(e) => {
            if (!props.newPage || props.noAction) e.preventDefault()
        }} href={props.user_id && Utils.base_url + "profile/" + props.user_id}>
            {box}
        </a>;
    }
    return (
        <div>
            {box}
        </div>
    );
}