import React from 'react';
import MenuItem from "./MenuItem";
import Utils from "../../utils";

class Menu extends React.Component {


    render() {
        let items = [];
        this.props.items.forEach(function (item) {
            items.push(<MenuItem key={Utils.nextKey()} url={item.url} props={item}/>);
        });

        return (
            <div className="Menu">
                <ul>
                    {items}
                </ul>
            </div>
        );
    }

}

export default Menu;