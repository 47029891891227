import React from "react";
import T from "i18n-react";
import Utils from "../../utils";
import Parser from "html-react-parser";
import {Icon} from "react-font-awesome-5"

export default class ChallengeObjectives extends React.Component {
    constructor(props) {
        super(props);

        this.fields = [];
        this.objectiveRefs = {};

        let data = props.bundle.data;
        if (!data)
            data = {};

        this.checkboxChange = this.checkboxChange.bind(this);
        this.setRef = this.setRef.bind(this);

        this.initialValue = data["challenge_objective.objective"] ? data["challenge_objective.objective"].split(String.fromCharCode(0x1D)) : [];
        this.values = this.initialValue.slice();

        this.state = {
            saving: false,
            changed: false
        };

        let _this = this;

        props.attr.objectives.forEach(function (objective) {
            let checked = _this.initialValue.indexOf(objective) > -1;
            _this.fields.push(
                <label className={"objective"}> <input type="checkbox" className={"checkbox"} name={objective} ref={(ref) => _this.setRef(objective, ref)} defaultChecked={checked} onChange={_this.checkboxChange}/><span/> {T.translate("label-objective_" + objective)}
                </label>
            )
        });

        function resetObjectives() {
            Object.keys(_this.objectiveRefs).forEach(function (_obj) {
                _this.objectiveRefs[_obj].checked = (_this.initialValue.indexOf(_obj) > -1);
            });
        }

        this.save_func = function () {
            if (_this.state.saving)
                return;
            _this.setState({saving: true});
            let post_data = {
                name: props.attr.name,
                value: _this.values
            };
            Utils.post(props.bundle.endpoint, post_data,
                () => {
                    _this.initialValue = _this.values.slice();
                    resetObjectives();
                    Utils.setFieldChanged(props.name, false);
                    _this.setState({changed: false, saving: false});
                }, () => {
                    Utils.setFieldChanged(props.name, false);
                    _this.setState({saving: false});
                }
            );
        };

        this.changed_field_icons = (<span className={"changed-field-icons absolute" + (this.state.saving ? " saving" : "")}>
        <Icon.Times onClick={(e) => {
            e.stopPropagation();
            if (_this.state.saving)
                return;
            Utils.setFieldChanged(props.name, false);
            _this.values = _this.initialValue.slice();
            resetObjectives();
            _this.setState({changed: false, saving: false});
            Utils.success("label-discarded_changes");
        }}/>
        <Icon.Check onClick={(e) => {
            e.stopPropagation();
            _this.save_func();
        }}/>
        </span>);

        Utils.registerEvent("save_all", () => {
            if (_this.state.changed)
                _this.save_func();
        });
    }

    setRef(name, ref) {
        this.objectiveRefs[name] = ref;
    }

    checkboxChange(e) {
        let idx = this.values.indexOf(e.target.name);
        if (e.target.checked && idx < 0) {
            this.values.push(e.target.name);
        } else if (idx > -1) {
            this.values.splice(idx, 1);
        }
        let _this = this;
        let changed = (this.values.length !== this.initialValue.length) || !this.values.every((val) => {
            return _this.initialValue.indexOf(val) > -1
        });
        Utils.setFieldChanged(_this.props.name, changed);
        this.setState({changed: changed});
    }

    render() {
        let changed_field_icons;
        if (this.state.changed) {
            changed_field_icons = this.changed_field_icons;
        }
        if (this.props.attr.editable)
            return (
                <div className="ChallengeObjectives">
                    <span className={"label_span"}>{T.translate(this.props.attr.label)}</span><span style={{color: "red"}}> *</span> {this.props.attr.instructions && <div key={Utils.nextKey()} className="challenge-objectives-description">
                    {Parser(T.translate(this.props.attr.instructions))}
                </div>}
                    <div className={"Field" + (this.state.changed ? " changed-value" : "")}>
                        {changed_field_icons}
                        <div className={"objectives-container"}>
                            {this.fields}
                        </div>
                    </div>
                </div>
            );
        else {
            let value = '';
            this.values.sort().forEach(function (value_) {
                value += ", " + T.translate("label-objective_" + value_);
            });
            value = value.substring(2);
            return (
                <div className="ChallengeObjectives">
                    <span className={"label_span"}>{T.translate(this.props.attr.label)}</span> {this.props.attr.instructions && <div key={Utils.nextKey()} className="challenge-objectives-description">
                    {Parser(T.translate(this.props.attr.instructions))}
                </div>}
                    <div className="field-value">{value}</div>
                </div>
            );
        }
    }
}