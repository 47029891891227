import SockJS from "sockjs-client";
import Centrifuge from 'centrifuge';
import Utils from "./utils";

let Functions = (function () {
    let centrifuge = null;
    let subscriptions = {};

    function centrifugePrepare() {
        Utils.get("centrifugo/token", null, function (connection_info) {
            centrifuge = new Centrifuge({
                url: 'https://centrifugo.ca.appx.pt/connection',
                user: connection_info.user_id,
                timestamp: connection_info.timestamp,
                token: connection_info.token,
                sockJS: SockJS,
                authEndpoint: Utils.getFullUrl("centrifugo/auth")
            });

            centrifuge.on('connect', function () {
                console.log("Centrifuge Connected!");

                Functions.centrifugeSubscribe('$CHAT_' + window.App.state.user.id, function (message) {
                    if (window.Chat) {
                        window.Chat.newMessage(message);
                    }
                });

                Functions.centrifugeSubscribe('$NOTES_' + window.App.state.user.id, function (note) {
                    if (window.Feed) {
                        window.Feed.updateNote(note);
                    }
                });
            });

            centrifuge.on('disconnect', function (context) {
                if (context.reason !== 'client') {
                    centrifuge.connect();
                }
            });

            centrifuge.on('error', function (error_message) {
                Utils.error(error_message.message.error);
            });

            centrifuge.connect();
        });
    }

    function centrifugeDestroy() {
        if (centrifuge) {
            centrifuge.disconnect();
            centrifuge = null;
        }
    }

    function centrifugeSubscribe(channel, cb) {
        let subs = centrifuge.subscribe(channel, {message: cb});
        subscriptions[channel] = subs;
    }

    function centrifugeUnsubscribe(channel) {
        subscriptions[channel].unsubscribe();
        delete subscriptions[channel];
    }

    return {
        centrifugePrepare: centrifugePrepare,
        centrifugeDestroy: centrifugeDestroy,
        centrifugeSubscribe: centrifugeSubscribe,
        centrifugeUnsubscribe: centrifugeUnsubscribe
    }
})();

export default Functions;