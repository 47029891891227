import Collapsible from "react-collapsible";
import $ from "jquery";
import React from "react";
import {Icon} from "react-font-awesome-5";

export default function (props) {
    let title = null;
    let text = null;
    let classname = "Box" + (props.className ? " " + props.className : "");
    if (props.title)
        title = (<h3>{props.title}{props.collapsible && <Icon.AngleDown/>}</h3>);
    if (props.text)
        text = (<p className="instructions">{props.text}</p>);
    if (props.collapsible) {
        return (<Collapsible open={props.responsive && $(window).width() > 700} openedClassName={classname} className={classname} trigger={title || ""}>
            {props.children}

        </Collapsible>);
    } else {
        return (
            <div className={"Box" + (props.className ? " " + props.className : "")}>
                {title} {text} <div className={"box-content"}>{props.children}</div>
            </div>
        );
    }
}