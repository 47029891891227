import React from 'react';
import Utils from "../../utils";
import T from 'i18n-react';

class MenuItem extends React.Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        e.preventDefault();
        Utils.openUrl(this.props.url);
    }

    render() {
        let label = this.props.props.label;
        return (
            <li><a className={this.props.props.active ? "active" : "" + (this.props.props.extra && this.props.props.extra.class ? " " + this.props.props.extra.class : '')} href={Utils.base_url + this.props.url} onClick={this.onClick}>{T.translate(label)}</a></li>
        );
    }

}

export default MenuItem;