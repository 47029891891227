import Utils from "../../utils";
import React from "react";

export default function (props) {
    let style = Utils.parseStyles(props.style || '');
    let image = <img className="img" style={style} alt={props.alt || ""} src={Utils.getFullUrl(props.src)} onError={function (e) {
        if (props.default)
            e.target.src = Utils.getFullUrl(props.default);
    }}/>;
    if (props.use_background) {
        let background = "url('" + Utils.getFullUrl(props.src) + (props.default ? "'), url('" + Utils.getFullUrl(props.default) : "") + "')";
        let maxWidth = style['max-width'] || style['width'] || 300;
        let height = style['height'] || 100;
        style['marginBottom'] = style['marginBottom'] || 35;
        style['overflow'] = style['overflow'] || 'hidden';
        style['padding'] = style['padding'] || "0 10px";
        delete style['height'];
        delete style['max-width'];
        if (props.cols) {
            style['width'] = (100 / props.cols) - 0.5 + "%";
            style['display'] = "inline-block";
        }
        image = <span className={"img background " + (props.class)} style={style}><span style={{backgroundImage: background, backgroundSize: props.use_background === true ? 'contain' : props.use_background, maxWidth: maxWidth, height: height}}/></span>
    }
    return image;
}