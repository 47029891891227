import React from 'react';
import Utils from "../../utils";
import T from 'i18n-react';
import Elements from "../elements";

class ConversationsBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            conversations: null,
            selected: null
        };
        let _this = this;
        Utils.get(props.endpoint, {action: "getConversations"}, function (data) {
            _this.setState({conversations: data, selected: data[0]});
            if (!props.default_conversation)
                _this.selectConversation(data[0]);
            else {
                _this.selectConversation(_this.getConversation(props.default_conversation));
            }
        });
        this.selectConversation = this.selectConversation.bind(this);
        this.getConversation = this.getConversation.bind(this);
        this.newMessageOther = this.newMessageOther.bind(this);
        this.newConversationMessageOther = this.newConversationMessageOther.bind(this);
    }

    selectConversation(conversation) {
        delete conversation["has_new_message"];
        this.setState({selected: conversation});
        this.props.onConversationSelect && this.props.onConversationSelect(conversation);
    }

    newMessageOther(message) {
        let _conv = this.getConversation(message.conversation);
        if (_conv) {
            let _convIdx = this.state.conversations.indexOf(_conv);
            let _stateConv = this.state.conversations.slice();
            _stateConv.splice(_convIdx, 1);
            _conv["has_new_message"] = true;
            _stateConv.unshift(_conv);
            this.setState({conversations: _stateConv});
        }
    }

    conversationSeen(conversation) {
        let _conv = this.getConversation(conversation);
        if (_conv) {
            _conv["has_new_message"] = false;
            let _stateConv = this.state.conversations.slice();
            this.setState({conversations: _stateConv});
        }
    }

    newConversationMessageOther(conv) {
        console.log("new conversation:", conv);
        let _this = this;
        Utils.get(this.props.endpoint, {action: "getConversation", conversation: conv}, function (data) {
            console.log("got response:", data);
            let _stateConv = _this.state.conversations.slice();
            data["has_new_message"] = true;
            _stateConv.unshift(data);
            _this.setState({conversations: _stateConv});
        });
    }

    getConversation(conversation_id) {
        let found = null;
        this.state.conversations && this.state.conversations.forEach(function (conversation) {
            if (conversation['conversation.id'] === conversation_id.toString()) {
                found = conversation;
                return false;
            }
        });
        return found;
    }

    render() {
        let _this = this;
        return (<div className={"ConversationBar"}>
                <Elements.Box title={T.translate("label-conversations")}>
                    {this.state.conversations && this.state.conversations.map(function (conversation) {
                        let other_user = conversation.users.find(function (_conv_user) {
                            return _conv_user.id !== window.App.state.user.id;
                        });
                        return (<div className={"user clickable" + (conversation === _this.state.selected ? " active" : "") + (conversation["has_new_message"] ? " has_message" : "")} onClick={(e) => _this.selectConversation(conversation)}>
                            {
                                other_user && <React.Fragment>
                                    <Elements.Image alt={other_user["full_name"]} style={Utils.parseStyles("padding: 0; height: 40px; float: left; marginRight: 8px; marginBottom: 0; borderRadius: 50%; width: 40px")} src={"static/image/user/" + (other_user.image + "_s")} default={'static/image/user/no_image'} use_background="cover"/>
                                    <p>
                                        <span>{other_user["full_name"]}</span>
                                    </p>
                                </React.Fragment> ||
                                <React.Fragment>
                                    <Elements.Image alt={""} style={Utils.parseStyles("padding: 0; height: 40px; float: left; marginRight: 8px; marginBottom: 0; borderRadius: 50%; width: 40px")} src={'static/image/user/no_image'} default={'static/image/user/no_image'} use_background="cover"/>
                                    <p>
                                        <span>{conversation["conversation.name"]}</span>
                                    </p>
                                </React.Fragment>
                            }

                        </div>);
                    })}
                </Elements.Box>
                <a style={{marginTop: 8, marginBottom: 0}} className={"Button success block"} onClick={this.props.newConversation}>{T.translate("label-new_conversation")}</a>
            </div>
        );
    }
}

export default ConversationsBar;