import React from 'react';
import Utils from "../../utils";
import Parser from 'html-react-parser';
import Elements from "../elements";
import T from 'i18n-react';
import domToReact from 'html-react-parser/lib/dom-to-react';
import {Icon} from "react-font-awesome-5";

class Static extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null
        };
    }

    componentDidMount() {
        let this_ = this;
        Utils.get(this.props.endpoint, null, function (data) {
            if (this_.props.hide_hero) {
                Utils.getHeader().closeHero();
            } else {
                Utils.getHeader().openHero(<h2> {T.translate(this_.props.title)} </h2>);
            }
            window.App.setFullscreen(this_.props.fullscreen);
            this_.setState({content: data});
        });
    }

    componentWillUnmount() {
        window.App.setFullscreen(false);
    }

    render() {
        let content = this.state.content;
        let replace = function (domNode) {
            let attr = domNode.attribs;
            if (domNode.name === 'box')
                return (<Elements.Box {...attr}>{domToReact(domNode.children, {replace: replace})}</Elements.Box>);
            else if (domNode.name === 'img')
                return (<Elements.Image {...attr}>{domToReact(domNode.children, {replace: replace})}</Elements.Image>);

            else if (domNode.name === 'button') {
                return (<a className={"Button " + attr.class} style={Utils.parseStyles(attr.style)} onClick={attr.openurl ? (() => Utils.openUrl(attr.openurl, true)) : undefined}>
                    {domToReact(domNode.children, {replace: replace})}
                </a>)
            }
            else if (domNode.name && domNode.name.indexOf('icon.') === 0) {
                let name = domNode.name.substring(5);
                let RelIcon;
                for (let p in Icon) {
                    if (Icon.hasOwnProperty(p) && p.toLowerCase() === name) {
                        RelIcon = Icon[p];
                        break;
                    }
                }
                return (<RelIcon/>);
            }
            return domNode;
        };

        if (content)
            content = Parser(content, {
                replace: replace
            });
        return (
            <div className={"Static"}>
                {content}
            </div>
        );
    }
}

export default Static;