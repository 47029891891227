import React from 'react';
import T from 'i18n-react';
import Utils from "../../utils";
import logo from "../../assets/img/logo.svg";
import erasmus_plus from "../../assets/img/erasmus_plus.png";

class Footer extends React.Component {

    render() {
        return (
            <div className="Footer">
                <div className="container">
                    <div className="col-1-2 mobile-col-1-1">
                        <img src={logo} alt="Challenge Academy" className="logo"/>
                        <p style={{maxWidth: 450}}>{T.translate("label-footer_text_left")}</p>
                    </div>
                    <div className="col-1-2 mobile-col-1-1 links">
                        <div className="col-1-2 mobile-col-1-1">
                            <h2>{T.translate("label-footer_title_info")}</h2>{
                            this.props.links.map(function (item) {
                                return (<a key={Utils.nextKey()} className={"footerLink"} onClick={Utils.click(item.url)}>{T.translate(item.label)}</a>);
                            })
                        }
                        </div>
                        <div className="col-1-2 mobile-col-1-1">
                            <h2>{T.translate("label-footer_title_contact")}</h2>
                            <p><a className={"footerLink"} href="mailto:info@challengeacademy.app">info@challengeacademy.app</a></p>
                            <img src={erasmus_plus} style={{width: 220, marginTop: 5}}/>
                        </div>
                    </div>
                    <p className={"text-center"} style={{paddingTop: 25, clear: "both"}}>{T.translate("label-footer_signature", {year: (new Date()).getFullYear()})}</p>
                </div>
            </div>
        );
    }
}

export default Footer;