import React from 'react';
import axios from 'axios';
import T from 'i18n-react';
import Field from "./components/Field";
import Elements from "./components/elements";
import Search from "./components/templates/Search";
import Chat from "./components/templates/Chat";
import Feed from "./components/templates/Feed";
import Document from "./components/templates/Document";
import UserProfile from "./components/templates/UserProfile";
import List from "./components/templates/List";
import XRegExp from "xregexp";
import Static from "./components/templates/Static";
import $ from "jquery";
import {confirmAlert} from "react-confirm-alert";

XRegExp.install({namespacing: true});

window.T = T;

const _axios = axios.create({withCredentials: true, paramsSerializer: $.param});

let Utils = (function () {
    let header = null;
    let templates = {};
    let navbar;
    let pages;
    let events = {};
    let base_url = "/";
    let base_api_url = process.env.REACT_APP_BASE_API_URL;
    let defaultUrl;
    let footer;
    let currentPage;
    let documentChanges = {};
    let lastSuccess = {time: 0, message: ""};
    let emulateUser = null;
    let firebaseToken = null;

    function documentHasChanges() {
        return Object.keys(documentChanges).length > 0;
    }

    function isEmulating() {
        return !!emulateUser;
    }

    function initConfig(cb) {
        let urlParams = Utils.getUrlParameters(window.location.search);
        let emulate = urlParams.emulate;
        if (emulate) {
            Utils.success("Emulating user");
            emulateUser = parseInt(emulate);
        }
        if (urlParams.firebase_token)
            firebaseToken = urlParams.firebase_token;
        get(`app_config`, null, function (data) {
            setConfig(data);
            if (data.user) {
                window.App.setUser(data.user);
                window.App.setSplashPage(null);
                Utils.registerFirebase();
            }
            window.App.showNewsPopup(data.news_popup);
            let error = urlParams.error;
            if (error) {
                Utils.error(decodeURIComponent(error));
            }
            let success = urlParams.success;
            if (success)
                Utils.success(decodeURIComponent(success));
            cb();
        });
    }

    function setConfig(config) {
        T.setTexts(config.lang);
        templates = config.templates || [];
        pages = config.pages || [];
        navbar = [];
        (config.navbar || []).forEach(function (key) {
            let extra = {class: ""};
            if (key.endsWith("**") && key.startsWith("**")) {
                extra.class = "header_button special";
                key = key.substr(2, key.length - 4);
            } else if (key.endsWith("*") && key.startsWith("*")) {
                extra.class = "header_button";
                key = key.substr(1, key.length - 2);
            }
            navbar.push({url: key, label: (pages[key] && pages[key].label) || "Label", extra: extra});
        });
        defaultUrl = config.defaultUrl || navbar[0].url;
        footer = [];
        (config.footer || []).forEach(function (key) {
            footer.push({url: key, label: (pages[key] && pages[key].label) || "Label"});
        });
    }

    function getRoutingRegex(pattern) {
        if (pattern.match('/[^-:/_{}()a-zA-Z0-9|]/'))
            return false; // Invalid pattern


        // Turn "(/)" into "/?"
        pattern = preg_replace('#(/)#', '/?', pattern);
        pattern = pattern.replace(/(\|[a-zA-Z_-]*)/g, function (a, b) {
            return "_" + b.substr(1);
        });

        // Create capture group for ":parameter"
        let $allowedParamChars = '[a-zA-Z0-9_-]+)(|([a-zA-Z0-9_-]*)';
        let $allowedParamChars2 = '[a-zA-Z0-9_-]+';

        pattern = preg_replace(
            ':(' + $allowedParamChars + ')?',   // Replace ":parameter"
            '(?<$1>' + $allowedParamChars2 + ')', // with "(?<parameter>[a-zA-Z0-9\_\-]+)"
            pattern
        );

        // Create capture group for '{parameter}'
        pattern = preg_replace(
            '{(' + $allowedParamChars + ')}',    // Replace "{parameter}"
            '(?<$1_$3>' + $allowedParamChars2 + ')', // with "(?<parameter>[a-zA-Z0-9\_\-]+)"
            pattern
        );
        return pattern;
    }

    window.getRoutingRegex = getRoutingRegex;

    function preg_replace(pattern, replacement, string) {
        return XRegExp.replace(string, XRegExp(pattern, 'g'), replacement);
    }

    function getFullUrl(url) {
        return base_api_url + url.replace(/^\/+/, "");
    }

    function getTemplate(templateName, view) {
        if (templateName && view)
            return templates[templateName][view];
        return null;
    }

    function getNavbar() {
        return navbar;
    }

    function getFooter() {
        return footer;
    }

    function evalcode(e) {
        //window[atob("ZXZhbA==")] is eval
        return window[atob("ZXZhbA==")](e);
    }

    function checkConditions(conditions, data) {
        if (conditions) {
            let validated = false;
            if (data) {
                conditions.forEach(function (cond) {
                    if (validated) return;
                    let cond_value = cond.value ? "'" + cond.value + "'" : null;
                    let cond_data = data[cond.name] ? "'" + data[cond.name] + "'" : null;
                    validated = Utils.evalcode(cond_data + cond.type + cond_value);
                    if (cond.and) {
                        validated = validated && checkConditions(cond.and, data);
                    }
                });
            }
            return validated;
        }
        return true;
    }

    function recursiveDraw(struct, bundle, custom_key) {
        let data = bundle.data;
        let d = [];
        struct.forEach(function (field) {
            if (!checkConditions(field.conditions, data)) {
                return;
            }
            if (field._component_type) {
                if (field._component_type === "Hidden")
                    return;
                let RenderElement = Elements[field._component_type];
                if (RenderElement) {
                    d.push(<RenderElement key={custom_key || Utils.nextKey()} title={T.translate(field['_title_label'])} text={T.translate(field['_text'])} attr={field} bundle={bundle}>
                        {field["_fields"] && recursiveDraw(field["_fields"], bundle)}
                    </RenderElement>);
                } else {
                    d.push(<div style={{backgroundColor: "red", minHeight: "25px"}} key={custom_key || Utils.nextKey()}>
                        Missing RenderElement ({field._component_type}) {field["_fields"] && recursiveDraw(field["_fields"], bundle)}
                    </div>);
                }
            } else if (field.name) {
                d.push((
                    <Field key={Utils.nextKey()} attr={field} name={field.name} bundle={bundle} value={data ? data[field.name] : 'thisistheplaceholdertext'.substr(0, 5 + Math.random() * 15)}/>
                ));
            } else {
                d.push((<span style={{backgroundColor: "red"}}>Missing field properties</span>));
            }
        });
        return (
            <div key={Utils.nextKey()}>{d}</div>
        )
    }

    function parseJSON(json) {
        try {
            return JSON.parse(json);
        } catch (e) {
            return null;
        }
    }

    function click(url) {
        return function (events) {
            openUrl(url);
        }
    }

    function clearDocumentChanges() {
        documentChanges = {};
        header.closeWarning();
    }

    function pageStackOp(page_url) {
        let pagestack = parseJSON(window.sessionStorage.getItem("ca-pagestack")) || [];
        let stackId = pagestack.length > 0 ? pagestack[pagestack.length - 1].id + 1 : 0;
        pagestack.push({page: page_url, id: stackId});
        window.sessionStorage.setItem("ca-pagestack", JSON.stringify(pagestack));
        return stackId; //inserted stackid
    }

    function popStackOp() {
        let pagestack = parseJSON(window.sessionStorage.getItem("ca-pagestack")) || [];
        if (pagestack.length < 1) return null;
        let popS = pagestack.pop();
        window.sessionStorage.setItem("ca-pagestack", JSON.stringify(pagestack));
        return popS;
    }

    function openPage(page, pushState, cameFromPop) {
        if (cameFromPop) {
            //flush events (unregister triggers)
            if (documentHasChanges()) {
                //window.history.go(1);
                //window.history.pushState(page, page.page.label, base_url + page.url);
                confirmLeaveChanges(() => { //save
                    events = {};
                    clearDocumentChanges();
                    openPage(page, pushState, false);
                }, () => { //cancel
                    if (page) {
                        let popped = popStackOp();
                        page.stackId = popped.id;
                        window.history.pushState(page, page.page.label, base_url + popped.page);
                    }
                });
            } else {
                openPage(page, pushState, false);
            }
        } else {
            if (!page) {
                window.App.setSplashPage(null);
                return false;
            }
            currentPage = page;
            let template = getTemplate(page.page.template, page.page.view);
            window.App.setBody(getBody(page.page, template, page.parsed_endpoint));
            let stackId = pageStackOp(page.url);
            if (pushState) {
                page.stackId = stackId;
                window.history.pushState(page, page.page.label, base_url + page.url);
            }

            return true;
        }
    }

    function getCurrentPage() {
        return currentPage;
    }

    function confirmLeaveChanges(callback, cancel_cb) {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="container react-confirm-alert-body">
                        <h1>{T.translate("label-confirmation")}</h1>
                        <p>{T.translate("label-confirm_leave_changes")}</p>
                        <div className="react-confirm-alert-button-group">
                            <a className={"Button margin success"} onClick={() => {
                                triggerEvent("save_all");
                                onClose();
                                setTimeout(function () {
                                    clearDocumentChanges();
                                    callback();
                                }, 100);
                            }}>{T.translate("label-save")}</a> <a className={"Button margin danger"} onClick={() => {
                            clearDocumentChanges();
                            callback();
                            onClose();
                        }}>{T.translate("label-discard")}</a> <a className={"Button margin"} onClick={() => {
                            cancel_cb && cancel_cb();
                            onClose();
                        }}>{T.translate("label-cancel")}</a>
                        </div>
                    </div>
                )
            }
        })
    }

    function openUrl(url, preventPushState) {
        function _openUrl(url, preventPushState) {
            let page = getPageFromUrl(url);
            if (page && page.page.action) {
                switch (page.page.action) {
                    case "logout":
                        window.App.logout();
                        break;
                    case "open_static":
                        window.App.setBody(<Static key={Utils.nextKey()} title={page.page.label} hide_hero={page.page.hide_hero} endpoint={page.page.endpoint} {...page.page}/>);
                        !preventPushState && window.history.pushState(page, page.page.label, base_url + page.url);
                        break;
                    case "open_splashscreen":
                        window.App.setSplashPage(page.page.page);
                        break;
                    default:
                        break;
                }

            } else {
                openPage(page, !preventPushState);
            }
            if (header)
                header.closeMenu();
            setNavbarActive(url);
        }

        if (documentHasChanges()) {
            confirmLeaveChanges(() => _openUrl(url, preventPushState));
        } else {
            _openUrl(url, preventPushState)
        }
    }

    function setNavbarActive(url) {
        for (let key in navbar) {
            navbar[key].active = navbar[key].url === url;
        }
    }

    function prompt(prompt, callback) {
        confirmAlert({
            customUI: ({onClose}) => {
                let value = T.translate(prompt.default);
                return (
                    <div className='container react-confirm-alert-body'>
                        {prompt.prompt_title && <h1>{T.translate(prompt.title)}</h1>}<p>{T.translate(prompt.message)}</p>
                        <div className={"Field"}>
                            <label> <textarea onChange={(e) => value = e.target.value} defaultValue={value} autoFocus={true}/></label>
                        </div>
                        <div className="react-confirm-alert-button-group">
                            <a className={"Button margin special"} onClick={() => {
                                switch (prompt.action) {
                                    case 'post_request':
                                        let endpoint = Utils.parseEndpoint(prompt.endpoint, Utils.getCurrentPage().parameters);
                                        Utils.post(endpoint, {text: value});
                                        break;
                                    default:
                                        break;
                                }
                                if (callback)
                                    callback(value);
                                onClose()
                            }}>{T.translate(prompt.submit_label || "label-submit")}</a> <a className={"Button margin"} onClick={onClose}>{T.translate("label-cancel")}</a>
                        </div>
                    </div>
                )
            }
        });
    }

    function confirm(message, title, confirm_callback, confirm_label, options) {
        confirmAlert({
            customUI: ({onClose}) => {
                let value = T.translate(prompt.default);
                return (
                    <div className={'container react-confirm-alert-body' + (options && options.wide ? " wide" : "")}>
                        {title && <h1>{T.translate(title)}</h1>}{typeof message === 'string' ? <p>{T.translate(message)}</p> : <div>{message}</div>}
                        <div className="react-confirm-alert-button-group">
                            <a className={"Button margin special"} onClick={() => {
                                if (confirm_callback)
                                    confirm_callback();
                                onClose();
                            }}>{T.translate(confirm_label || "label-confirm")}</a> {confirm_callback && (<a className={"Button margin"} onClick={onClose}>{T.translate("label-cancel")}</a>)}
                        </div>
                    </div>
                )
            }
        });
    }

    function getPageFromUrl(url) {
        let _urls = Object.keys(pages);
        let found_page = null;
        _urls.forEach(function (_url) {
            let parameters = XRegExp.exec(url, XRegExp(getRoutingRegex(_url), 'g'));
            if (parameters) {
                parameters = parameters.groups;
                if (parameters) {
                    let _parameter_keys = Object.keys(parameters);
                    let invalid = false;
                    let param_aux = {};
                    _parameter_keys.forEach(function (_param_key) {
                        let tokens = _param_key.split("_");
                        if (tokens[tokens.length - 1] === 'num' && isNaN(parameters[_param_key])) {
                            invalid = true;
                            return false;
                        }
                        tokens.pop();
                        param_aux[tokens.join("_")] = parameters[_param_key];
                    });
                    if (invalid)
                        return true;
                    parameters = param_aux;
                }
                found_page = {
                    url: url,
                    page: pages[_url],
                    parameters: parameters,
                    parsed_endpoint: parseEndpoint(pages[_url].endpoint, parameters)
                };
                return false;
            }
        });

        if (found_page == null) {
            if (url === '')
                Utils.openDefault();
            else {
                if (window.App.state.user) {
                    Utils.openUrl("404", true);
                } else {
                    Utils.openUrl("login", true);
                }
            }
        }

        return found_page;
    }

    function parseEndpoint(url, parameters) {
        if (url) {
            if (parameters) {
                Object.keys(parameters).forEach(function (key) {
                    url = preg_replace("\\(:" + key + "\\)", parameters[key], url);
                });
            }
            //special cases:
            window.App.state.user && (url = url.replace(/%SESSION_USER_ID%/g, window.App.state.user.id));
        }
        return url;
    }

    function getBody(page, template, endpoint) {
        let body;
        switch (page.type) {
            case "document":
                body = (<Document key={Utils.nextKey()} endpoint={endpoint} template={template} page={page}/>);
                break;
            case "list":
                body = (<List key={Utils.nextKey()} endpoint={endpoint} title={page.label} template={template} page={page}/>);
                break;
            case "search":
                body = (<Search key={Utils.nextKey()} endpoint={endpoint} title={page.label} config={page.config} page={page}/>);
                break;
            case "feed":
                body = (<Feed key={Utils.nextKey()} endpoint={endpoint} title={page.label} config={page.config} page={page}/>);
                break;
            case "profile":
                body = (<UserProfile key={Utils.nextKey()} endpoint={endpoint} title={page.label} template={template} page={page}/>);
                break;
            case "chat":
                body = (<Chat key={Utils.nextKey()} endpoint={endpoint} title={page.label} config={page.config} page={page}/>);
                break;
            default:
                getHeader().closeHero();
                body = (<div key={Utils.nextKey()}/>);
                break;
        }
        return body;
    }

    function setHeader(_header) {
        header = _header;
    }

    function openCurrentUrl() {
        let url = window.location.pathname;
        if (url.substr(0, base_url.length) === base_url)
            url = url.substring(base_url.length);
        openUrl(url, true);
    }

    function openDefault() {
        openUrl(defaultUrl);
    }

    function getDefaultUrl() {
        return defaultUrl;
    }

    function getHeader() {
        return header;
    }

    function nl2br(text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

    function post(url, params, success, error, options) {
        window.Pace.restart();
        if (!params) {
            params = {};
        }
        if (emulateUser) {
            params._emulate = emulateUser;
        }
        _axios.post(getFullUrl(url), params, {
            withCredentials: true,
            onUploadProgress: function (progressEvent) {
                if (options && options.uploadProgress) {
                    let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    options.uploadProgress(progress);
                }
            }
        })
            .then(res => {
                window.Pace.stop();
                if (res.headers['content-type'] === "application/json" && typeof res.data === "string") {
                    Utils.error("Invalid response", "label-error");
                    return;
                }
                if (res.data.message) {
                    if (res.data.result === "success") {
                        Utils.success(T.translate(res.data.message), null, 3500);
                    } else if (res.data.result === "warning") {
                        Utils.notificationManager.warning(T.translate(res.data.message), null, 6500);
                    }
                }
                if (res.data.result === "reload") {
                    if (res.data.message)
                        Utils.success(T.translate(res.data.message), null, 2000);
                    Utils.triggerEvent("reload");
                } else if (res.data.result === "redirect") {
                    if (res.data.message)
                        Utils.success(T.translate(res.data.message), null, 4500);
                    if (res.data.location.indexOf("splashscreen") === 0) {
                        window.App.setSplashPage(res.data.location.substr("splashscreen".length + 1));
                    } else
                        openUrl(res.data.location);
                } else if (res.data.result === "prompt") {
                    prompt(res.data.config, function (text) {
                    });
                } else {
                    success(res.data.data);
                }
            }).catch(err => {
            window.Pace.stop();
            if (err.response) {
                /*if (err.response.status === 500)
                    Utils.notificationManager.error(T.translate("label-server_error"), '', 500);*/
                if (err.response.headers['content-type'] === "application/json" && typeof err.response.data === "string") {
                    Utils.error("Invalid response", "label-error");
                    return;
                }
                if (err.response.data.message) {
                    if (typeof err.response.data.message === 'string')
                        err.response.data.message = [err.response.data.message];
                    err.response.data.message.forEach(function (message, idx) {
                        setTimeout(() => Utils.notificationManager.error(T.translate(message), '', 4000), idx * 100);
                    });
                }
                if (error)
                    error(err.response.data, err.response.status);

                if (err.response.status === 401) {
                    window.App.logout("login");
                }
            }
        });
    }

    function get(url, params, success, error) {
        if (!params) {
            params = {};
        }
        if (emulateUser) {
            params._emulate = emulateUser;
        }
        url = getFullUrl(url);
        _axios.get(url, {withCredentials: true, params: params})
            .then(res => {
                if (res.headers['content-type'] === "application/json" && typeof res.data === "string") {
                    Utils.error("Invalid response", "label-error");
                    return;
                }
                if (res.data.message)
                    if (res.data.result === "success") {
                        Utils.notificationManager.success(T.translate(res.data.message), null, 3500);
                    } else if (res.data.result === "warning") {
                        Utils.notificationManager.warning(T.translate(res.data.message), null, 4500);
                    }
                if (res.data.data) {
                    success(res.data.data);
                } else {
                    success(res.data);
                }
            }).catch(err => {
            if (err.response) {
                /*if (err.response.status === 500)
                    Utils.notificationManager.error(T.translate("label-server_error"), '', 500);*/
                if (err.response.headers['content-type'] === "application/json" && typeof err.response.data === "string") {
                    Utils.error("Invalid response", "label-error");
                    return;
                }
                if (err.response.data.message) {
                    if (typeof err.response.data.message === 'string')
                        err.response.data.message = [err.response.data.message];
                    err.response.data.message.forEach(function (message, idx) {
                        setTimeout(() => Utils.notificationManager.error(T.translate(message), '', 4000), idx * 100);
                    });
                }
                if (error)
                    error(err.response.data, err.response.status);

                if (err.response.status === 401) {
                    window.App.logout("login");
                }
            }
        });
    }

    function textWithHyperLinks(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1" target="_blank" class="url">$1</a>');
    }

    let current_key = 0;

    function nextKey() {
        return "k" + (++current_key);
    }

    function parseStyles(string) {
        if (typeof string === "string") {
            let styles = {};
            string.split(";").forEach(function (style) {
                let tokens = style.split(":");
                if (tokens[0] && tokens[1])
                    styles[tokens[0].trim()] = tokens[1].trim();
            });
            return styles;
        }
        return string;
    }

    function error(message, title, duration) {
        Utils.notificationManager.error(T.translate(message), T.translate(title), duration || 4500);
    }

    function success(message, title, duration) {
        let time = Date.now();
        if (lastSuccess.time + 1000 < time || message !== lastSuccess.message) {
            Utils.notificationManager.success(T.translate(message), T.translate(title), duration || 4000);
        }
        lastSuccess = {time: time, message: message};
    }

    function registerEvent(event, callback) {
        if (!events[event])
            events[event] = [];
        events[event].push(callback);
    }

    function unregisterEvent(event, cb) {
        if (events[event]) {
            let idx = events[event].indexOf(cb);
            idx >= 0 && delete events[event][idx];
        }
    }

    function triggerEvent(event) {
        let arguments_ = arguments;
        if (events[event]) {
            events[event].forEach(function (callback) {
                callback(...arguments_);
            });
        }
    }

    function setFieldChanged(field, changed) {
        if (changed) {
            documentChanges[field] = true;
            header.showWarning(T.translate("label-warning_document_changes"));
        } else {
            delete documentChanges[field];
            if (!documentHasChanges()) {
                header.closeWarning();
            }
        }
    }

    function resetFocus() {
        let scrollTop = document.body.scrollTop;
        let body = document.body;

        let tmp = document.createElement('input');
        tmp.style.opacity = 0;
        body.appendChild(tmp);
        tmp.focus();
        body.removeChild(tmp);
        body.scrollTop = scrollTop;
    }

    function getUrlParameters(url) {
        if (!url) url = window.location.search;
        var query = url.substr(1);
        var result = {};
        query.split("&").forEach(function (part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    function registerFirebase() {
        if (firebaseToken) {
            Utils.post("notifications/firebase/register", {token: firebaseToken});
        }
    }

    return {
        setHeader: setHeader,
        initConfig: initConfig,
        getTemplate: getTemplate,
        getNavbar: getNavbar,
        getFooter: getFooter,
        recursiveDraw: recursiveDraw,
        click: click,
        openUrl: openUrl,
        getHeader: getHeader,
        openPage: openPage,
        parseJSON: parseJSON,
        nl2br: nl2br,
        getFullUrl: getFullUrl,
        post: post,
        get: get,
        setConfig: setConfig,
        openCurrentUrl: openCurrentUrl,
        nextKey: nextKey,
        parseStyles: parseStyles,
        success: success,
        error: error,
        textWithHyperLinks: textWithHyperLinks,
        registerEvent: registerEvent,
        unregisterEvent: unregisterEvent,
        triggerEvent: triggerEvent,
        openDefault: openDefault,
        parseEndpoint: parseEndpoint,
        getCurrentPage: getCurrentPage,
        getDefaultUrl: getDefaultUrl,
        setFieldChanged: setFieldChanged,
        documentHasChanges: documentHasChanges,
        evalcode: evalcode,
        resetFocus: resetFocus,
        prompt: prompt,
        base_url: base_url,
        confirm: confirm,
        isEmulating: isEmulating,
        DATE_RFC2822: "ddd, DD MMM YYYY HH:mm:ss ZZ",
        DATE_DATETIMEFORMAT: "YYYY-MM-DD HH:mm:ss",
        getUrlParameters,
        registerFirebase
    }
})();


window.Utils = Utils;

export default Utils;