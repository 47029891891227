import Utils from "../../utils";
import T from "i18n-react";
import React from "react";
import {Icon} from "react-font-awesome-5";
import Elements from "./";

export default function (data) {
    let user_fullname;
    if (data['user.name'] && data['user.surname'])
        user_fullname = data['user.name'].trim().split(" ")[0] + " " + data['user.surname'].trim().split(" ").reverse()[0];
    return (<Elements.Box title={data.title} className={"UserListItem " + data.className}> <Elements.Image alt={user_fullname} style={Utils.parseStyles("width: 150px; height: 150px; marginBottom: 0; padding: 0")} src={"static/image/user/" + data['user.image'] + "_m"} default={'static/image/user/no_image'} use_background="cover"/>
        <a onClick={(e) => e.preventDefault()} href={data['user.id'] && Utils.base_url + "profile/" + data['user.id']}>
        <span className="info clickable" onClick={data['user.id'] ? () => Utils.openUrl("profile/" + data['user.id']) : undefined}>
        <h1 className="clickable">{user_fullname}</h1>
        <div style={{clear: "both"}}/>
        <div className="details"><p><Icon.User/> {T.translate("label-" + (data['user_type.designation'] || '').toLowerCase())}</p>{data['country.name'] && (<p><Icon.Globe/> {data['country.name']}</p>)} {data['organization.name'] && (<p><Icon.Briefcase/> {data['organization.name']}</p>)}</div>
        </span> </a> </Elements.Box>);
}