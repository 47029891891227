import React from 'react';
import Utils from "../../utils";
import T from 'i18n-react';
import Elements from "../elements";
import {Icon} from "react-font-awesome-5";
import ReactTooltip from "react-tooltip";
import Parser from 'html-react-parser';
import $ from "jquery";

class Conversation extends React.Component {

    constructor(props) {
        super(props);
        this.messages = [];
        this.comp_messages = [];
        let _this = this;
        this.lastReceived = this.lastNotified = 0;
        Utils.get(props.endpoint, {action: "getMessages", conversation: props['conversation.id']}, function (data) {
            _this.messages = data;
            _this.messages.forEach(function (message, index) {
                _this.comp_messages.push(<Message key={Utils.nextKey()} date={message["conversation_message.date"]} message={message["conversation_message.message"]} mine={message["conversation_message.user"] === window.App.state.user.id}/>);
                _this.lastReceived = parseInt(message["conversation_message.id"]);
            });
            _this.lastNotified = _this.lastReceived;
            _this.forceUpdate();
        });

        this.newMessage = this.newMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.enabled = true;

        $(window).on("focus", function () {
            if (!_this.enabled)
                return;
            if (_this.lastReceived !== _this.lastNotified) {
                Utils.post("chat", {action: "markConversationSeen", conversation: _this.props['conversation.id']}, function (data) {
                    _this.lastNotified = _this.lastReceived;
                });
            }
        });
    }

    onKeyDown(e) {
        e = e || window.event;

        if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
            e.preventDefault();
            this.sendMessage();
        } else {
            let element = e.target;
            if (element.scrollHeight === 68) {
                element.style.height = "auto";
                element.style.height = "50px";
            }
            if (element.scrollHeight > 40) {
                element.style.height = "auto";
                element.style.height = (element.scrollHeight + 10) + "px";
            }
        }
    }

    sendMessage() {
        let message = this.inputRef.value;
        if (this.inputRef.disabled || message == null || message.trim().length < 1) {
            return;
        }
        Utils.resetFocus();
        this.inputRef.disabled = true;
        let _this = this;
        Utils.post("chat", {action: "sendMessage", conversation: this.props['conversation.id'], message: message}, function (message) {
            _this.inputRef.value = "";
            _this.inputRef.disabled = false;
            _this.inputRef.focus();
            _this.newMessage(message);
        }, function () {
            _this.inputRef.disabled = false;
            _this.inputRef.focus();
        });
    }

    render() {
        return (<Elements.Box className={"Conversation"}>
                <div ref="conv_wrapper" className={"wrapper"}>
                    {this.comp_messages}
                </div>

                <div className={"messageInput"}>
                    <textarea autoFocus={true} ref={(ref) => this.inputRef = ref} onKeyDown={this.onKeyDown} placeholder={T.translate("label-chat_input_placeholder")}/> <Icon.PaperPlane onClick={this.sendMessage}/>
                </div>
            </Elements.Box>

        );
    }

    newMessage(message) {
        //Notify server that message was seen
        let _this = this;
        this.lastReceived = message.message_id;
        this.comp_messages.push(<Message key={Utils.nextKey()} date={message.date} message={message.message} mine={message.from_user.toString() === window.App.state.user.id}/>);
        if (document.hasFocus())
            Utils.post("chat", {action: "markConversationSeen", conversation: this.props['conversation.id']}, function (data) {
                _this.lastNotified = message.message_id;
            });
        this.forceUpdate();
    }

    componentDidMount() {
        this.refs.conv_wrapper.scrollTop = this.refs.conv_wrapper.scrollHeight;
    }

    componentDidUpdate() {
        this.refs.conv_wrapper.scrollTop = this.refs.conv_wrapper.scrollHeight;
    }

    componentWillUnmount() {
        this.enabled = false;
    }
}

function Message(props) {
    return (<div className={"Message"}><span data-tip={props.date} className={props.mine ? "right" : "left"}>{Parser(Utils.nl2br(props.message))}</span><ReactTooltip effect="solid" place="top"/></div>);

}

export default Conversation;