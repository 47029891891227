import React from 'react';
import Utils from "../../utils";
import Elements from "../elements";
import T from 'i18n-react';

class Document extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: null
        };
    }

    componentDidMount() {
        let this_ = this;

        function load() {
            Utils.get(this_.props.endpoint, null, function (data) {
                Utils.getHeader().openHero(<h2> {data['document.title']} </h2>);
                this_.setState({document: data});
            }, function (data, status) {
                status === 404 && window.history.back();
            });
        }

        this.reload_callback = function () {
            if (Utils.documentHasChanges()) {
                Utils.confirmLeaveChanges(load);
            } else {
                load();
            }
        };
        Utils.registerEvent("reload", this.reload_callback);
        load();
    }

    render() {
        let doc_public = this.state.document && this.state.document['template'] === 'public';
        let components = (<DoIT bodyTemplate={this.props.template} parent={this} data={this.state.document ? this.state.document : null} endpoint={this.props.endpoint} page={this.props.page}/>);
        return (
            <div className={"grid-wrapper"}>
                <div className={"Document " + (doc_public ? "col-2-3 " : '') + (this.state.document ? "" : "loading")}>
                    {components}
                </div>
                {doc_public &&
                <div className={"col-1-3"}>
                    <Elements.UserBoxLarge title={T.translate("label-author")}  {...this.state.document}/>
                </div>}
                <div style={{clear: "both"}}/>
            </div>
        );
    }

    componentWillUnmount() {
        Utils.unregisterEvent("reload", this.reload_callback);
    }
}

function DoIT(bundle) {
    return Utils.recursiveDraw(bundle.bodyTemplate, bundle);
}

export default Document;