import React from 'react';
import Utils from "../../utils";
import T from 'i18n-react';
import Elements from "../elements";
import Select2 from "../../other/react-select2-wrapper";
import $ from "jquery";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.filters_comp = [];
        this.filters = {};
        let This = this;

        this.getFilters = this.getFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);

        Object.keys(props.filters).forEach(function (filter_name) {
            This.filters_comp.push(<This.Filter ref={(ref) => {
                This.filters[filter_name] = ref
            }} key={Utils.nextKey()} {... props.filters[filter_name]} name={filter_name} onChange={() => This.applyFilters()}/>);
        });
    }

    Filter = class Filter extends React.Component {

        constructor(props) {
            super(props);
            this.inputChanged = this.inputChanged.bind(this);

            let content = null;
            let _this = this;
            let initialValue = _this.props.default && _this.props.default[0];
            switch (_this.props.type) {
                case "checkbox_group":
                    let _default = _this.props.default[0] || [];
                    if (_this.props.options) {
                        content = [];
                        _this.props.options.forEach(function (field) {
                            content.push(<label className={"checkBox"} key={Utils.nextKey()}><input defaultChecked={_default.indexOf(field[0]) >= 0} onChange={_this.inputChanged} type="checkbox" name={_this.props.name} value={field[0]}/>{T.translate(field[1])}</label>);
                        });
                    } else {
                        Utils.get(this.props.endpoint, null, function (data) {
                            let content = [];
                            data.result.forEach(function (data) {
                                content.push(<label className={"checkBox"} key={Utils.nextKey()}><input defaultChecked={_default.indexOf(data[_this.props.value_field]) >= 0} onChange={_this.inputChanged} type="checkbox" name={_this.props.name} value={data[_this.props.value_field]}/>{data[_this.props.text_field]}</label>);
                            });
                            _this.setState({content: content});
                        });
                    }
                    break;
                case "select_multiple":
                    let more_results = true;
                    let _options = {
                        multiple: true,
                        width: '100%',
                        ajax: {
                            url: _this.props.endpoint,
                            dataType: 'json',
                            delay: 200,
                            data: function (params) {
                                return {
                                    query: params.term,
                                    page: params.page || 0
                                };
                            },
                            processResults: function (data, params) {
                                params.page = params.page || 0;
                                if (params.page === 0 && !more_results)
                                    more_results = true;
                                let results = [];
                                data.result.forEach(function (item) {
                                    if (item['children']) {
                                        results = data.result;
                                        return false;
                                    }
                                    results.push({
                                        id: item[_this.props.value_field],
                                        text: item[_this.props.text_field],
                                        data: item
                                    });
                                });

                                if (results.length < 1 || (data.limit && results.length < data.limit))
                                    more_results = false;
                                return {
                                    results: results,
                                    pagination: {more: more_results}
                                };
                            },
                            transport: function (params, success, failure) {
                                Utils.get(params.url, params.data, (data) => {
                                    success(data);
                                }, (data) => {
                                    failure();
                                });
                            }
                        },
                    };
                    let select_data;
                    let select_default_value;

                    if (_this.props.default && _this.props.default[0] && _this.props.default[1]) {
                        let aux = [];
                        let values;
                        if (typeof _this.props.default[0] === "string") {
                            values = _this.props.default[0].split(String.fromCharCode(0x1D));
                        } else {
                            values = _this.props.default[0];
                        }
                        let text = _this.props.default[1].split(String.fromCharCode(0x1D));

                        for (let i = 0; i < values.length; i++) {
                            aux.push({id: values[i], text: text[i], data: text[i]});
                        }

                        select_data = aux;
                        select_default_value = values;
                        initialValue = values;
                    }

                    content = (<Select2 defaultValue={select_default_value} options={_options} multiple={true} data={select_data} onChange={(e) => {
                        _this.inputChanged(e);
                        more_results = true;
                    }}/>);
                    break;
                default:
                    break;
            }

            this.state = {
                content: content,
                value: initialValue || null
            };

        }

        inputChanged(e) {
            let value = null;
            if (this.props.type === "select_multiple")
                value = $(e.target).val();
            else if (this.props.type === "checkbox_group") {
                let curr = this.state.value;
                if (!curr)
                    curr = [];
                if (e.target.checked) {
                    curr.push(e.target.value);
                } else {
                    let idx = curr.indexOf(e.target.value);
                    if (idx >= 0) {
                        curr.splice(curr.indexOf(e.target.value), 1);
                    }
                }
                value = curr;
            }
            this.setState({value: value});
            this.props.onChange && this.props.onChange();
        }

        getValue() {
            let value = this.state.value;
            if (value && ((typeof value === "string" && value.trim() === '') || (typeof value === 'object' && value.length === 0)))
                return null;
            return this.state.value;
        }

        render() {
            return (<div className="Filter">
                <span className={"filterLabel"}>{T.translate(this.props.label)}</span> {this.state.content}
            </div>);
        }
    };

    getFilters() {
        let filters = {};
        let _this = this;
        Object.keys(this.filters).forEach(function (filter_name) {
            let value = _this.filters[filter_name].getValue();
            if (value)
                filters[filter_name] = value;
        });
        return filters;
    }

    componentDidMount() {
        this.applyFilters();
    }

    applyFilters() {
        let filters = this.getFilters();
        this.props.setFilters && this.props.setFilters(filters);
    }

    render() {
        return (<Elements.Box responsive={true} collapsible={true} title={T.translate("label-advanced_search")} className={"SearchBar"}>
            {this.filters_comp} <Elements.Button onClick={() => {
            let filters = this.getFilters();
            this.props.onApplyFilters(filters);
        }} label={T.translate("label-apply_filters")} class={"special block"}/> </Elements.Box>);
    }
}

export default SearchBar;