import React from 'react';
import '../../assets/styles/simplegrid.css';

import box from "./box";
import deleteAccountForm from "./deleteAccountForm";
import spannedHeader from "./spannedHeader";
import profileImageSelection from "./profileImageSelection";
import passwordInput from "./passwordInput";
import image from "./image";
import header from "./header";
import challengeDescriptionForm from "./challengeDescriptionForm";
import challengeObjectives from "./challengeObjectives";
import userDocumentList from "./userDocumentList";
import searchResultList from "./searchResultList";
import documentListItem from "./documentListItem";
import userBoxLarge from "./userBoxLarge";
import attachment from "./attachment";
import button from "./button";
import userBox from "./userBox";
import resultListItem from "./resultListItem";


let Elements = {
    Box: box,
    DeleteAccountForm: deleteAccountForm,
    SpannedHeader: spannedHeader,
    ProfileImageSelection: profileImageSelection,
    PasswordInput: passwordInput,
    ChallengeObjectives: challengeObjectives,
    ChallengeDescriptionForm: challengeDescriptionForm,
    Header: header,
    Image: image,
    UserDocumentList: userDocumentList,
    SearchResultList: searchResultList,
    DocumentListItem: documentListItem,
    UserBoxLarge: userBoxLarge,
    ResultListItem: resultListItem,
    UserBox: userBox,
    Button: button,
    Attachment: attachment
};

export default Elements;