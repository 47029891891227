import React from "react";
import Utils from "../../utils";
import Parser from "html-react-parser";
import T from "i18n-react";
import Field from "../Field";

export default class ChallengeDescriptionForm extends React.Component {
    constructor(props) {
        super(props);

        this.field_refs = {};

        let data = props.bundle.data;
        if (!data)
            data = {};

        let fields = [];
        if (!props.attr.form) {
            return (
                <span key={Utils.nextKey()} className="bad-config">Missing "form" attribute on ChallengeDescriptionForm</span>
            );
        }

        let parsed_values = Utils.parseJSON(data[props.attr.name]);
        if (parsed_values == null || typeof parsed_values.length !== "undefined") {
            parsed_values = {};
        }

        let _this = this;
        Utils.registerEvent("save_all", this.save_all_callback = () => {
            props.attr.form.forEach((_field) => {
                let _ref = _this.field_refs[_field];
                parsed_values[_field] = _ref.getValue();
            });
            let post_data = {
                name: props.attr.name,
                value: JSON.stringify(parsed_values)
            };
            Utils.post(props.bundle.endpoint, post_data,
                () => {
                    props.attr.form.forEach((_field) => {
                        let _ref = _this.field_refs[_field];
                        Utils.setFieldChanged(props.attr.name + _field, false);
                        _ref.setState({saving: false, changed: false});
                        _ref.initialValue = parsed_values[_field];
                    });

                },
                () => {
                    props.attr.form.forEach((_field) => {
                        let _ref = _this.field_refs[_field];
                        _ref.setState({saving: false});
                    });

                }
            );
        });

        function saveForm(_field_ref, _field, value) {
            parsed_values[_field] = value;
            let post_data = {
                name: props.attr.name,
                value: JSON.stringify(parsed_values)
            };
            Utils.post(props.bundle.endpoint, post_data,
                () => {
                    Utils.setFieldChanged(props.attr.name + _field, false);
                    _field_ref.setState({saving: false, changed: false});
                    _field_ref.initialValue = value;
                },
                () => _field_ref.setState({saving: false})
            );
        }

        if (props.attr.instructions && props.attr.instructions !== "") {
            fields.push(
                <div key={Utils.nextKey()} className="form-description">{Parser(T.translate(props.attr.instructions))}</div>
            );
        }

        props.attr.form.forEach(function (_field) {
            let value = parsed_values[_field];
            if (!props.attr.editable && (value == null || value === "")) {
                return;
            }
            let field_attr = {
                label: T.translate("form_question-challenge_description-" + _field),
                data_type: "varchar",
                editable: props.attr.editable,
                save: function (_field_ref, value, save_all) {
                    if (!save_all) {
                        props.attr.editable && saveForm(_field_ref, _field, value);
                    } else {
                        //console.log("IGNORED");
                    }
                },
                onChanged: function (changed, value) {
                    Utils.setFieldChanged(props.attr.name + _field, changed);
                }
            };

            fields.push(
                <Field ref={(ref) => {
                    if (ref) {
                        _this.field_refs[_field] = ref;
                    }
                }} key={Utils.nextKey()} attr={field_attr} value={value}/>
            );
        });

        if (!props.attr.editable && fields.length === 0) {
            /*fields.push(
                <span key={Utils.nextKey()}>{T.translate("label-nothing_here")}</span>
            );*/
        }
        this.fields = fields;
    }

    componentWillUnmount() {
        Utils.unregisterEvent("save_all", this.save_all_callback);
    }

    render() {
        return (
            <div>
                {this.fields}
            </div>
        );
    }
}