import Dropzone from "react-dropzone";
import Utils from "../../utils";
import {Icon} from "react-font-awesome-5";
import React from "react";
import T from "i18n-react";

export default class Attachment extends React.Component {
    constructor(props) {
        super();
        let config = props.attr.config;
        if (!config) {
            return;
        }

        let files = [];
        if (props.bundle.data && props.bundle.data[config.filename]) {
            let filenames = props.bundle.data[config.filename].split(String.fromCharCode(0x1D));
            let names = props.bundle.data[config.name].split(String.fromCharCode(0x1D));
            for (let i = 0; i < filenames.length; i++) {
                files.push({name: names[i], filename: filenames[i]});
            }
        }

        this.state = {
            files: files,
            initial_files: files.slice(0),
            changed: false,
            saving: false,
            progress: 0
        };
        let _this = this;
        this.save = function () {
            if (_this.state.saving)
                return;
            _this.setState({saving: true});
            let data = new FormData();
            _this.state.files.forEach((file) => {
                let _file = file.file;
                data.append('files[]', _file);
                delete file['file'];
                data.append('file_info[]', JSON.stringify(file));
            });
            data.append("name", config.upload_name);

            Utils.post(this.props.bundle.endpoint, data,
                (data) => {
                    Utils.setFieldChanged(_this.props.attr.id, false);
                    _this.setState({
                        saving: false,
                        changed: false,
                        files: data.files,
                        initial_files: data.files.slice(0),
                        progress: 0
                    });
                    Utils.success("label-attachment_upload_success");
                },
                () => {
                    _this.setState({saving: false});
                }, {
                    uploadProgress: function (progress) {
                        _this.setState({progress: progress});
                    }
                });
        };
        this.changed_field_icons = props.attr.editable && (<span className={"changed-field-icons" + (this.state.saving ? " saving" : "")}>
        <Icon.Times onClick={(e) => {
            e.stopPropagation();
            if (this.state.saving)
                return;
            Utils.setFieldChanged(_this.props.attr.id, false);
            _this.setState({changed: false, files: _this.state.initial_files.slice(0), saving: false});
            Utils.success("label-discarded_changes");
        }}/>
        <Icon.Check onClick={(e) => {
            e.stopPropagation();
            _this.save();
        }}/>
        </span>);

        Utils.registerEvent("save_all", () => {
            if (_this.state.changed)
                _this.save();
        });
    }

    render() {
        if (!this.props.attr.config) {
            return (<span style={{backgroundColor: "red"}}>Missing attachment config</span>);
        }
        let _this = this;
        if (this.props.attr.editable)
            return (
                <section className="Attachment">
                    <div className={"dropzone" + (_this.state.changed ? " temp" : '') + (_this.state.saving ? " saving" : "")} progress={this.state.progress}>
                        <Dropzone accept="image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-powerpoint, text/plain, application/pdf" style={{
                            width: "100%",
                            minHeight: "100px",
                            borderWidth: "2px",
                            borderColor: "rgba(102, 102, 102, 0.27)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                        }} acceptStyle={{
                            width: "100%",
                            minHeight: "100px",
                            borderWidth: "2px",
                            borderColor: "rgba(0, 166, 36, 0.68)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                        }} rejectStyle={{
                            width: "100%",
                            minHeight: "100px",
                            borderWidth: "2px",
                            borderColor: "rgba(219, 0, 0, 0.72)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                        }} onDrop={(accepted, rejected) => {
                            if (accepted.length === 0)
                                return;
                            let files = _this.state.files;
                            accepted.forEach(function (file) {
                                let _file = {
                                    name: file.name,
                                    file: file
                                };
                                files.push(_file);
                            });
                            Utils.setFieldChanged(_this.props.attr.id, true);
                            this.setState({
                                files: files,
                                changed: true
                            });
                        }}>
                            {this.state.files.length === 0 && (<h1 className="placeholder">{T.translate("label-drop_files")}<br/>
                                <small>{T.translate("label-drop_files_sub")}</small>
                            </h1>)} {this.state.files.map(function (f) {
                            let download = null;
                            if (f.filename) {
                                download = (<a href={Utils.getFullUrl((_this.props.attr.config.download_route || "download") + "/" + f.filename)} onClick={(e) => e.stopPropagation()} target="_blank"><Icon.Download/></a>);
                            }
                            return (<span className={"file" + (f.filename ? "" : " temp")} key={f.name}>{download} {f.name}{f.filename ? "" : " (" + T.translate("label-click_save_upload") + ")"} <Icon.Times onClick={function (e) {
                                e.stopPropagation();
                                let files = _this.state.files;
                                files.splice(files.indexOf(f), 1);
                                _this.setState({files: files, changed: true});
                                Utils.setFieldChanged(_this.props.attr.id, false);
                            }}/></span>)
                        })} {this.state.files.length > 0 && (<h4 className="placeholder">{T.translate("label-drop_files_add")}<br/>
                            <small>{T.translate("label-drop_files_sub")}</small>
                        </h4>)} {this.state.changed && this.changed_field_icons}
                        </Dropzone>
                    </div>
                </section>
            );
        else
            return (<div className="Attachment not_editable">
                <div className="grid">
                    {this.state.files.map(function (f) {
                        return (
                            <span className={"file mobile-col-1-1 col-1-3"} key={f.name}>
                            <a className="Button block" href={Utils.getFullUrl((_this.props.attr.config.download_route || "download") + "/" + f.filename)} onClick={(e) => e.stopPropagation()} target="_blank">
                                {f.name}{f.filename ? "" : " (" + T.translate("label-click_save_upload") + ")"} <Icon.Download/>
                            </a>
                        </span>)
                    })}
                </div>
            </div>)
    }
}