import * as React from "react";
import Form from "../templates/Form";
import Utils from "../../utils";
import Box from "../elements/box";
import T from "i18n-react";
import Parser from "html-react-parser";
import $ from "jquery";

export default class RegisterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_type: null,
            user_information: null,
            other_information: null,
            terms: null,
            email_notifications: null,
            page: 1
        };

        this.schemas = {
            basicInformation: [
                {
                    "type": "input:text",
                    "name": "name",
                    "label": "label-name"
                },
                {
                    "type": "input:text",
                    "name": "surname",
                    "label": "label-surname"
                },
                {
                    "type": "input:text",
                    "name": "email",
                    "label": "label-email"
                },
                {
                    "type": "input:password",
                    "name": "password",
                    "label": "label-password"
                },
                {
                    "type": "input:password",
                    "name": "password_confirm",
                    "label": "label-password_confirm"
                },
                {
                    "type": "button:special",
                    "action": "submit",
                    "label": "label-continue"
                },
                {
                    "type": "button",
                    "action": "goto:login",
                    "label": "label-cancel"
                }
            ],
            accountType: [
                {
                    "type": "select:usertype",
                    "endpoint": "partials/usertype/search",
                    "name": "user_type",
                    "label": "label-user_type",
                    "value_field": "user_type.id",
                    "text_field": "user_type.designation",
                    "translate_text_field": true,
                    "preventSearch": true,
                    "value": this.state.user_type
                },
                {
                    "type": "button:special",
                    "action": "submit",
                    "label": "label-continue"
                },
                {
                    "type": "button",
                    "action": "special",
                    "label": "label-back"
                }
            ],
            studentForm: [
                {
                    "type": "select:mainarea",
                    "endpoint": "partials/mainarea/search",
                    "name": "main_area",
                    "label": "label-main_area",
                    "value_field": "main_area.id",
                    "text_field": "main_area.designation"
                },
                {
                    "type": "select:country",
                    "endpoint": "partials/country/search",
                    "name": "country",
                    "label": "label-country",
                    "value_field": "country.id",
                    "text_field": "country.name"
                },
                {
                    "type": "button:special",
                    "action": "submit",
                    "label": "label-continue"
                },
                {
                    "type": "button",
                    "action": "special",
                    "label": "label-back"
                }
            ],
            additionalForm: [
                {
                    "type": "select:mainarea",
                    "endpoint": "partials/mainarea/search",
                    "name": "main_area",
                    "label": "label-main_area",
                    "value_field": "main_area.id",
                    "text_field": "main_area.designation"
                },
                {
                    "type": "select:researchline",
                    "endpoint": "partials/researchline/search",
                    "name": "research_lines",
                    "label": "label-research_lines",
                    "value_field": "research_line.id",
                    "text_field": "research_line.designation",
                    "multiple": true
                },
                {
                    "type": "select:country",
                    "endpoint": "partials/country/search",
                    "name": "country",
                    "label": "label-country",
                    "value_field": "country.id",
                    "text_field": "country.name"
                },
                {
                    "type": "button:special",
                    "action": "submit",
                    "label": "label-continue"
                },
                {
                    "type": "button",
                    "action": "special",
                    "label": "label-back"
                }
            ],
            termsNewsletter: [
                {
                    "type": "input:checkbox",
                    "name": "terms_and_conditions",
                    "label": "label-tos_privacypolicy_form_agree",
                    "required": true
                },
                {
                    "type": "input:checkbox",
                    "name": "email_notifications",
                    "label": "label-register_receive_emails",
                    "required": true
                },
                {
                    "type": "button:special",
                    "action": "submit",
                    "label": "label-register_account"
                },
                {
                    "type": "button",
                    "action": "special",
                    "label": "label-back"
                }
            ]
        };

        this.callbacks = {
            basicInformation: (data) => {
                if (this.verifyFields(data, this.schemas.basicInformation))
                    this.setState({user_information: data, page: this.state.page + 1});
            },
            accountType: (data) => {
                if (this.verifyFields(data, this.schemas.accountType))
                    this.setState({user_type: data.user_type, page: this.state.page + 1});
            },
            studentForm: (data) => {
                if (this.verifyFields(data, this.schemas.studentForm)) {
                    this.setState({other_information: data, page: this.state.page + 1});
                }
            },
            additionalForm: (data) => {
                if (this.verifyFields(data, this.schemas.additionalForm)) {
                    this.setState({other_information: data, page: this.state.page + 1});
                }
            },
            termsNewsletter: (data) => {
                if (!data.terms_and_conditions) {
                    Utils.error("label-tos_required");
                    return;
                }
                this.setState({email_notifications: data.email_notifications, terms: data.terms_and_conditions});
                Utils.post("register", {
                    ...this.state.user_information,
                    ...this.state.other_information,
                    user_type: this.state.user_type,
                    terms_and_conditions: data.terms_and_conditions,
                    email_notifications: data.email_notifications
                }, (data) => {
                    window.App.setSplashPage("login");
                }, (data) => {
                    console.log(data);
                });
            }
        };

        this.forms = {};
        for (let i in this.schemas) {
            this.forms[i] = <Form noBox key={Utils.nextKey()} attr={{
                title: "label-register",
                body: this.schemas[i]
            }} specialAction={() => {
                this.setState({page: this.state.page - 1})
            }} onSubmit={this.callbacks[i]}/>;
        }

        this.getPage = this.getPage.bind(this);
        this.verifyFields = this.verifyFields.bind(this);
    }

    render() {
        let currentPage = this.getPage();
        return (<Box title={T.translate("label-welcome_")} text={currentPage.instructions}>
            {Object.keys(this.forms).map((form) => {
                return <div style={{display: currentPage.form === form ? "block" : "none"}}>{this.forms[form]}</div>;
            })}
        </Box>);
    }

    getPage() {
        let form;
        let instructions;
        if (this.state.page === 1) {
            instructions = Parser(T.translate("label-register_intro"));
            form = "basicInformation";
        } else if (this.state.page === 2) {
            instructions = Parser(T.translate("label-register_intro_type"));
            form = "accountType";
        } else if (this.state.page === 3) {
            instructions = Parser(T.translate("label-register_intro_other"));
            if (parseInt(this.state.user_type) === 1)
                form = "studentForm";
            else
                form = "additionalForm"
        } else if (this.state.page === 4) {
            instructions = Parser(T.translate("label-register_tos_pp"));
            form = "termsNewsletter";
        }
        return {form, instructions};
    }

    verifyFields(data, schema) {
        for (let i in schema) {
            let field = schema[i];
            let tokens = field.type.split(":");
            if (tokens[0] === 'input' || tokens[0] === 'select') {
                if (!data[field.name]) {
                    Utils.error(T.translate("label-input_required", {field: T.translate(field.label)}));
                    return false;
                }
                if (typeof data[field.name] === 'string' && data[field.name].trim() === '') {
                    Utils.error(T.translate("label-input_required", {field: T.translate(field.label)}));
                    return false;
                }
                if (typeof data[field.name] === 'object' && data[field.name].length === 0) {
                    Utils.error(T.translate("label-input_required", {field: T.translate(field.label)}));
                    return false;
                }
            }
        }
        return true;
    }
}