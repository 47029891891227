import React from 'react';
import Utils from "../../utils";
import T from 'i18n-react';
import MasonryInfiniteScroller from 'react-masonry-infinite';
import HeroActions from "../sections/HeroActions";
import logo_simple from "../../assets/img/logo_simple.svg";

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            hasMore: true
        };
        this.count = 0;
        this.loading = false;
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        let _this = this;
        let header = (<h2> {T.translate(this.props.title)} </h2>);
        if (this.props.page.header_actions)
            header = (<div>
                <h2 className={"col-1-2"}> {T.translate(this.props.title)} </h2>
                <HeroActions className="col-1-2" actions={this.props.page.header_actions}/>
                <div style={{clear: "both"}}></div>
            </div>);
        Utils.getHeader().openHero(header);
        Utils.get(this.props.endpoint, null, function (data) {
            _this.setState({data: data.result});
            _this.refs.masonry.forcePack();
        }, function (data, status) {
            status === 404 && window.history.back();
        });
        this.oldwindowresize = window.onresize;
        window.onresize = function () {
            _this.refs.masonry.forcePack();
        };
    }

    componentWillUnmount() {
        window.onresize = this.oldwindowresize;
    }


    render() {

        let template = this.props.template;
        let sizes = [
            {columns: 1, gutter: 10},
            {mq: '650px', columns: 2, gutter: 10},
            {mq: '1024px', columns: 3, gutter: 10}
        ];
        return (
            <div className={"List " + (this.state.data ? "" : "loading")}>
                <MasonryInfiniteScroller ref="masonry" className={"masonry"} sizes={sizes} hasMore={this.state.hasMore} loadMore={this.loadMore} packed={"packed-list"} pack={true} loader={
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"/>
                        <div className="sk-cube2 sk-cube"/>
                        <div className="sk-cube4 sk-cube"/>
                        <div className="sk-cube3 sk-cube"/>
                    </div>
                }>
                    {this.state.data && this.state.data.map(item =>
                        Utils.recursiveDraw(template, {data: item}, ++this.count)
                    )}
                </MasonryInfiniteScroller> {this.state.data && this.state.data.length < 1 && this.props.page.no_items_placeholder && (<div className={"text-center"}>
                <img src={logo_simple} width={150} style={{opacity: 0.3, marginTop: 30, marginBottom: 10}}/>
                <h2 className={"placeholder"}>{T.translate(this.props.page.no_items_placeholder)}</h2></div>)}
            </div>
        );
    }

    loadMore(page) {
        if (this.loading)
            return;
        this.loading = true;
        let _this = this;
        Utils.get(this.props.endpoint, {page: page}, function (data) {
            _this.loading = false;
            _this.setState({data: _this.state.data.concat(data.result), hasMore: data.result.length > 0}, () => {
                if (_this.state.data.length > 0)
                    _this.refs.masonry.forcePack();
            });
        });
    }
}

export default List;