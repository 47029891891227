import * as React from "react";
import {Icon} from "react-font-awesome-5";
import Utils from "../../utils";
import T from 'i18n-react';
import Dropzone from 'react-dropzone';
import Elements from "./";

export default class ProfileImageSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changed: false,
            file: null,
            saving: false,
            image: (props.bundle.data && props.bundle.data['user.image']) || '',
            progress: 0
        };

        let _this = this;

        this.changed_field_icons = (<span className={"changed-field-icons"}>

        <Icon.Times onClick={(e) => {
            e.stopPropagation();
            if (this.state.saving)
                return;
            Utils.setFieldChanged(_this.props.attr.name, false);
            _this.setState({changed: false, file: null, saving: false});
            Utils.success("label-discarded_changes");
        }}/>
        <Icon.Check onClick={(e) => {
            e.stopPropagation();
            if (this.state.saving)
                return;
            _this.setState({saving: true});
            let data = new FormData();
            data.append('file', _this.state.file);
            data.append("name", props.attr.name);

            Utils.post(this.props.bundle.endpoint, data,
                (data) => {
                    Utils.setFieldChanged(_this.props.attr.name, false);
                    _this.setState({saving: false, changed: false, file: null, image: data.image, progress: 0});
                    Utils.success("label-profile_image_upload_success");
                },
                () => {
                    _this.setState({saving: false});
                }, {
                    uploadProgress: function (progress) {
                        _this.setState({progress: progress});
                    }
                });

        }}/>
        </span>);
    }

    render() {
        let _this = this;
        let f = this.state.file;
        return (
            <div className="Field"><label><span style={{marginBottom: "5px", display: "block"}}>{T.translate("label-user_image")}</span></label>
                <Elements.Image alt={this.state.image} style={Utils.parseStyles("padding: 0; height: 100px; float: left; marginRight: 8px; marginBottom: 0; borderRadius: 50%; width: 100px")} src={"static/image/user/" + this.state.image + "_m"} default={'static/image/user/no_image'} use_background="cover"/>
                <section className="Attachment" style={{width: "calc(100% - 108px)", float: "left"}}>
                    <div className={"dropzone" + (_this.state.changed ? " temp" : '') + (_this.state.saving ? " saving" : "")} progress={this.state.progress}>
                        <Dropzone accept="image/*" multiple={false} style={{
                            width: "100%",
                            minHeight: "100px",
                            borderWidth: "2px",
                            borderColor: "rgba(102, 102, 102, 0.27)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                        }} acceptStyle={{
                            width: "100%",
                            minHeight: "100px",
                            borderWidth: "2px",
                            borderColor: "rgba(0, 166, 36, 0.68)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                        }} rejectStyle={{
                            width: "100%",
                            minHeight: "100px",
                            borderWidth: "2px",
                            borderColor: "rgba(219, 0, 0, 0.72)",
                            borderStyle: "dashed",
                            borderRadius: "5px",
                        }} onDrop={(accepted, rejected) => {
                            if (accepted.length === 0)
                                return;
                            Utils.setFieldChanged(_this.props.attr.name, true);
                            _this.setState({
                                file: accepted[0],
                                changed: true
                            });
                        }}>
                            {f && (<span className={"file" + (f.filename ? "" : " temp")} key={f.name}>
                                    {f.name}
                                <small> ({T.translate("label-click_save_upload")})</small>

                                </span>)} {!f && (<span style={{minHeight: "100px", display: "table"}}><h4 className="placeholder" style={{margin: "0", padding: "8px", display: "table-cell", verticalAlign: "middle"}}>{T.translate("label-drop_files_single")}</h4></span>)} {this.state.changed && this.changed_field_icons}
                        </Dropzone>
                    </div>
                </section>
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}