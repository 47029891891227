import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregister();

/*
//Uncomment when in production
let devtools = /./;
let _stc = Date.now();
devtools.toString = function() {
    if(Date.now()>_stc) {
        console.log("console opened " + Date.now());
        document.getElementById('root').remove();
        delete window.App;
        delete window.Utils;
    }
};
console.log(devtools);
*/