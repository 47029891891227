import Form from "../templates/Form";
import Utils from "../../utils";
import React from "react";

export default function (props) {
    let register_body = [
        {type: "input:password", name: "password", label: "label-password"},
        {type: "button:special", action: "submit:register", label: "label-delete_account"}
    ];
    return <Form noBox={true} key={Utils.nextKey()} attr={{
        body: register_body
    }} onSubmit={(formData) => {
        Utils.confirm("label-confirm_delete_account", null, function () {
            Utils.post(props.bundle.endpoint, {name: "delete_account", value: formData.password}, function () {
                window.App.logout();
            });
        });
    }}/>
}