import * as React from "react";
import T from "i18n-react";
import Utils from "../../utils";
import {Icon} from "react-font-awesome-5";
import UserBox from "../elements/userBox";
import logo_simple from "../../assets/img/logo_simple.svg";

export default class UserSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: null,
            searching: true,
            selected: null
        };
        this.search_input = null;

        this.searchUsers = this.searchUsers.bind(this);
        this.getSelected = () => this.state.selected;
    }

    render() {
        return <div className="UserSearch">
            <span className={"searchInput dark"}>
                <input autoFocus={true} type="text" onKeyUp={(e) => {
                    e = e || window.event;
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        this.searchUsers(this.search_input.value);
                    }
                }} placeholder={T.translate("label-search_input_placeholder")} ref={(ref) => this.search_input = ref}/>
                <Icon.Search onClick={() => this.searchUsers(this.search_input.value)}/>
            </span>
            <div className="result-wrapper">{this.state.results && (this.state.results.length > 0 ? this.state.results.map((data) => {
                let user_fullname = data['user.name'].trim().split(" ")[0] + " " + data['user.surname'].trim().split(" ").reverse()[0];
                return <UserBox onClick={() => {
                    this.setState({selected: data['user.id']});
                }} selected={this.state.selected === data['user.id']} noAction fullWidth name={user_fullname} user_id={data['user.id']} use_dark_text={true} organization={data["organization.name"]} image={"static/image/user/" + data['user.image']}/>;
            }) : <div className={"text-center"}>
                <img src={logo_simple} width={150} style={{opacity: 0.3, marginTop: 30}}/>
                {<h1 className={"placeholder"}>{T.translate(this.state.searching ? "label-searching" : "label-user_search_placeholder_no_results")}</h1>}
            </div>)}</div>
        </div>
    }

    searchUsers(query) {
        if (query.trim() === '') {
            this.setState({results: null, searching: true});
            return;
        }
        this.setState({searching: true});
        Utils.get("search_users", {query: query}, (data) => {
            if (data.result)
                this.setState({results: data.result, searching: false});
        });
    }
}
