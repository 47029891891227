import React from "react";
import T from "i18n-react";
import Utils from "../../utils";
import Field from "../Field";

export default class PasswordInput extends React.Component {

    constructor(props) {
        super(props);
        this.old_pass_attr = {
            label: T.translate("label-current_password"),
            data_type: "password",
            force_editing: true,
            editable: true,
            save: "disabled"
        };

        this.new_pass_attr = {
            label: T.translate("label-new_password"),
            data_type: "password",
            force_editing: true,
            editable: true,
            save: savePassword,
            discardCallback: discardPasswords
        };

        let _this = this;

        function discardPasswords() {
            if (_this.refs.old_pass_field) {
                _this.refs.old_pass_field.setState({value: null, changed: false});
                Utils.setFieldChanged(props.attr.name, false);
            }
        }

        function savePassword(_field, new_pass) {
            let old_pass = _this.refs.old_pass_field && _this.refs.old_pass_field.getValue();
            let post_data = {
                name: props.attr.name,
                value: {
                    new_pass: new_pass,
                    old_pass: old_pass
                }
            };
            Utils.post(props.bundle.endpoint, post_data,
                () => {
                    Utils.setFieldChanged(props.attr.name, false);
                    _field.setState({saving: false, changed: false, value: null});
                },
                () => _field.setState({saving: false})
            );
        }
    }

    render() {
        return (<div className="password-input">
            <input type="search" style={{position: "fixed", top: -1000}}/>
            <input type="password" style={{position: "fixed", top: -1000}}/>
            <Field ref="old_pass_field" key={Utils.nextKey()} attr={this.old_pass_attr}/> <Field key={Utils.nextKey()} attr={this.new_pass_attr}/>
        </div>)
    }
}