import React from 'react';
import T from 'i18n-react';
import {Icon} from "react-font-awesome-5";
import Select2 from "../other/react-select2-wrapper";
import moment from "moment";
import InputMoment from "./external/input-moment.js";
import Utils from "../utils";
import Parser from "html-react-parser";
import $ from "jquery";
import stripTags from 'strip-tags';
import {confirmAlert} from "react-confirm-alert";
import validUrl from "valid-url";

class Field extends React.Component {
    constructor(props) {
        super(props);

        let data = (props.bundle && props.bundle.data) ? props.bundle.data : {};
        let type;
        switch (props.attr.data_type) {
            case "int":
            case "tinyint":
                type = "number";
                break;
            case "text":
                type = "textarea";
                break;
            case "checkbox":
                type = "checkbox";
                break;
            case "password":
                type = "password";
                break;
            case "datetime":
            case "timestamp":
                type = "datetime";
                break;
            default:
                type = "text";
                break;
        }

        this.initialValue = props.value;
        if (this.initialValue === undefined || this.initialValue === "0") {
            this.initialValue = null;
        }

        if (props.attr.data_type === 'checkbox' && props.value && (isNaN(props.value) || parseInt(props.value) !== 0)) {
            this.initialValue = true;
        }

        this.m = moment(this.initialValue || undefined);
        this.preventBlur = false;
        window.moment = moment;

        this.state = {
            data: data,
            type: type,
            editing: false,
            value: this.initialValue,
            changed: false,
            valid: true,
            progress: 0
        };

        this.select_extra_data = {};

        this.updated = false;

        this.inputChanged = this.inputChanged.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.dateTimeBlur = this.dateTimeBlur.bind(this);
        this.getValue = this.getValue.bind(this);
        this.select_onSelecting = this.select_onSelecting.bind(this);

        let _this = this;

        let save_func = (props.attr.save && props.attr.save !== "disabled") ? props.attr.save : function (_this, value) {
            if (_this.state.saving)
                return;
            if (!_this.state.valid) {
                Utils.error("label-invalid_field_value");
                return;
            }
            _this.setState({saving: true});
            let post_data = {
                name: _this.props.name,
                value: value
            };
            Utils.post(_this.props.bundle.endpoint, post_data,
                () => {
                    let newState = {saving: false, changed: false, progress: 0};
                    if (_this.props.attr.select || _this.state.type === "datetime") {
                        newState["editing"] = false;
                    }
                    Utils.triggerEvent("ca_field_trigger-" + post_data.name, {value: post_data.value});
                    _this.initialValue = value;
                    _this.setState(newState);
                    Utils.setFieldChanged(_this.props.name, false);
                    setTimeout(function () {
                        if (_this.rendered_select) {
                            try {
                                _this.rendered_select.select2("destroy");
                            } catch (e) {
                            }
                            _this.rendered_select = null;
                        }
                    }, 50);
                },
                () => {
                    _this.setState({saving: false});
                }, {
                    uploadProgress: function (progress) {
                        _this.setState({progress: progress});
                    }
                });
        };

        this.changed_field_icons = (<span className={"changed-field-icons" + (this.state.saving ? " saving" : "")}>
                <Icon.Times onClick={(e) => {
                    e.stopPropagation();
                    if (this.state.saving)
                        return;

                    Utils.setFieldChanged(_this.props.name, false);
                    if (props.attr.select) {
                        if (props.attr.select.data_fields) {
                            window.location.reload();
                        } else {
                            let $sel = $(_this.refs.select.el);
                            $sel.val(this.initialValue).trigger('change');
                        }
                    }
                    _this.setState({value: this.initialValue, changed: false, editing: false, valid: true});
                    if (this.props.attr.onChanged) {
                        this.props.attr.onChanged(false, this.initialValue);
                    } else {
                        Utils.setFieldChanged(_this.props.name, false);
                    }
                    if (_this.props.attr.discardCallback) {
                        _this.props.attr.discardCallback();
                    }
                    Utils.success("label-discarded_changes");
                }}/>
                <Icon.Check onClick={(e) => {
                    e.stopPropagation();
                    save_func(_this, _this.getValue());
                }}/>
            </span>);

        this.field_trigger_listener = function (event, data) {
            _this.setState({value: data.value});
        };

        if (this.props.bundle && this.props.bundle.data) {
            this.props.name && Utils.registerEvent("ca_field_trigger-" + this.props.name, this.field_trigger_listener);
            Utils.registerEvent("save_all", () => {
                if (_this.state.changed)
                    save_func(_this, _this.getValue(), true);
            });
        }
    }

    componentWillUnmount() {
        this.props.name && Utils.unregisterEvent("ca_field_trigger-" + this.props.name, this.field_trigger_listener);
    }

    onBlur(event) {
        if (this.state.type !== "password") {
            this.setState({editing: false});
        }
    }

    inputChanged(event) {
        let _val;
        let valid = true;
        if (this.state.type === "checkbox") {
            _val = !this.getValue();
        } else if (this.state.type === "datetime") {
            _val = this.m.locale("en").format(Utils.DATE_DATETIMEFORMAT);
        } else {
            valid = event.target.checkValidity();
            _val = event.target.value;
            if (valid && this.props.attr.data_type === "url") {
                valid = validUrl.is_web_uri(_val);
            }
        }
        if (this.refs.select) {
            _val = $(event.target).val();
            let splitDataText = [];
            $(event.target).select2("data").forEach(function (d) {
                splitDataText.push(d.text);
            });
            this.props.bundle.data[this.props.attr.select.text_field] = splitDataText.join(String.fromCharCode(0x1D));
            if (_val == null || _val.length === 0)
                _val = null;

            if (this.props.attr.select.create_option)
                this.has_createOption = false;
        }

        if (_val === "") {
            _val = null;
        }
        let changed_value = true;
        if (_val === this.initialValue) {
            changed_value = false;
        } else {
            changed_value = !(this.state.type === "checkbox" && this.initialValue == null && !_val);
        }

        let newState = {
            changed: changed_value,
            value: _val,
            editing: true,
            valid: valid
        };
        if (this.props.attr.onChanged) {
            this.props.attr.onChanged(changed_value, _val);
        } else {
            Utils.setFieldChanged(this.props.name, changed_value);
        }
        this.m = moment(_val);
        this.setState(newState);
        this.updated = true;
    }

    getValue() {
        return this.state.value;
    }


    componentDidUpdate() {
        if (!this.updated) {
            let textarea = this.refs.textarea;
            if (textarea) {
                textarea.style.height = "1px";
                textarea.style.height = (25 + textarea.scrollHeight) + "px";
            }
            let input = this.refs.input;
            if (input && !(this.props.attr.force_editing && this.state.value && this.state.changed)
                && (this.state.type === "text" || this.state.type === "textarea" || this.state.type === "email")) {
                input.setSelectionRange(this.state.focusOffset, this.state.focusOffset);
            }
            let select = this.refs.select;
            if (select) {
                //    $(select).on("change", this.inputChanged);
            }

            this.updated = true;
        }
        this.select_onSelecting();
    }

    select_onSelecting() {
        if (this.props.attr.select && this.props.attr.select.create_option && !this.has_onselecting) {
            let sel = this.refs.select;
            if (sel) {
                let sel_el = sel.el;
                let _this = this;
                sel_el.on('select2:selecting', function (e) {
                    if (e.params.args.data && e.params.args.data.id === "CREATEOPTION") {
                        e.preventDefault();
                        _this.CASelect2OptionActions[_this.props.attr.select.create_option.action](_this);
                        return false;
                    } else {
                        return true;
                    }
                });
                this.has_onselecting = true;
            }
        }
    }

    componentDidMount() {
        this.select_onSelecting();
    }

    dateTimeBlur(event) {
        if (!this.preventBlur) {
            this.setState({editing: false});
        }
        this.preventBlur = false;
    }

    render() {
        let _this = this;
        let props = this.props;
        let field;

        let changed_field_icons;
        if (this.state.changed && props.attr.save !== "disabled") {
            changed_field_icons = this.changed_field_icons;
        }

        if ((props.attr.force_editing || (this.props.attr.editable && (!this.state.value && !this.state.changed) && this.state.type !== "datetime") || this.state.editing)) {
            if (props.attr.select) {
                field = this.selectFactory(props, props.bundle.data);
            } else {
                let input;
                switch (this.state.type) {
                    case "textarea":
                        input = (
                            <textarea rows="3" ref="textarea" defaultValue={this.state.value ? this.state.value : ""} maxLength={this.props.attr.max_length} onChange={this.inputChanged} onBlur={this.onBlur}/>);
                        break;
                    case "checkbox":
                        input = (
                            <div>
                                <div className={"switch"}>
                                    <input type={this.state.type} ref="input" checked={this.state.value ? "checked" : false} onChange={() => {
                                    }}/> <span className="slider"/>
                                </div>
                            </div>
                        );
                        break;
                    case "datetime":
                        input = (
                            <React.Fragment> <input className="input" type="text" value={this.state.value} onChange={this.inputChanged} onBlur={this.dateTimeBlur} ref="input"/> <InputMoment moment={this.m} onMouseDown={(e) => {
                                if ($(e.target).closest(".m-input-moment")[0]) {
                                    this.preventBlur = true;
                                }
                            }} onChange={this.inputChanged} prevMonthIcon="fa-ca fa-chevron-left" nextMonthIcon="fa-ca fa-chevron-right"/> </React.Fragment>
                        );
                        break;
                    default:
                        input = (
                            <input placeholder={T.translate(this.props.attr.placeholder)} type={this.state.type} maxLength={this.props.attr.max_length} max={this.props.attr.max} min={this.props.attr.min} ref="input" value={this.state.value ? this.state.value : ""} onChange={this.inputChanged} onBlur={this.onBlur}/>);
                }
                field = (
                    <div className={"Field " + (this.state.changed ? " changed-value" : "") + (!this.state.valid ? " invalid" : "") + " editable"} progress={this.state.progress}>
                        <label onClick={(e) => {
                            if (this.state.type === "checkbox") {
                                if (e.target.tagName === "SPAN" && e.target.className === "slider") {
                                    this.inputChanged();
                                }
                                e.preventDefault();
                            }
                        }}> <T.span text={{key: props.attr.label}}/> {this.props.attr.show_length && this.props.attr.max_length && <small>({(this.state.value || '').length + "/" + this.props.attr.max_length})</small>} {props.attr.required && props.attr.editable && (<span className="required"> *</span>)} {changed_field_icons} {props.attr.instructions && (
                            <p className={"instructions"}>{this.getInstructions(props.attr.instructions)}</p>)} {input}
                        </label>
                    </div>
                );
            }

        } else {
            let value_;
            if (props.attr.select && props.bundle.data && props.bundle.data[props.attr.select.text_field]) {
                if (props.attr.select.template_selection) {
                    let val_select_aux = [];
                    let extra_keys = Object.keys(_this.select_extra_data);
                    if (extra_keys.length > 0) {
                        extra_keys.forEach(function (extra_d_val, extra_idx) {
                            let _course_aux = {data: {}};
                            _course_aux.data = _this.select_extra_data[extra_d_val];
                            val_select_aux.push(_this.CASelect2Templates.course_template_selection_react(_course_aux));
                        });
                    } else {
                        let _val_raw = props.bundle.data[props.attr.select.value_field] || "";
                        let _text_raw = props.bundle.data[props.attr.select.text_field] || "";
                        let _val_fields = _val_raw.split(String.fromCharCode(0x1D));
                        let _text_fields = _text_raw.split(String.fromCharCode(0x1D));
                        _val_fields.forEach(
                            function (val_field, val_idx) {
                                let _course_aux = {data: {}};
                                if (props.attr.select.data_fields) {
                                    props.attr.select.data_fields.forEach(function (data_field) {
                                        if (data_field !== props.attr.select.text_field && props.bundle.data[data_field])
                                            _course_aux.data[data_field] = props.bundle.data[data_field].split(String.fromCharCode(0x1D))[val_idx];
                                    });
                                }
                                _course_aux.data[props.attr.select.text_field] = _text_fields[val_idx];
                                val_select_aux.push(_this.CASelect2Templates.course_template_selection_react(_course_aux));
                            }
                        );
                    }
                    value_ = (<div>{val_select_aux}</div>);
                } else {
                    value_ = props.bundle.data[props.attr.select.text_field].split(String.fromCharCode(0x1D));
                    if (props.attr.select.translate)
                        for (let i = 0; i < value_.length; i++) {
                            value_[i] = T.translate("select-" + value_[i]);
                        }
                    value_ = value_.join(", ");
                }
            }

            if (this.state.type === "checkbox") {
                value_ = T.translate("label-" + (this.state.value ? "yes" : "no"));
            }

            field = (<div className={"Field" + (this.state.changed ? " changed-value" : "") + (!this.state.valid ? " invalid" : "") + (this.props.attr.editable ? " editable" : "") + (this.state.saving ? " saving" : "")} progress={this.state.progress}>

                <label> <T.span text={{key: props.attr.label}}/> {props.attr.required && props.attr.editable && (<span className="required"> *</span>)} {changed_field_icons} {props.attr.instructions && (<p className={"instructions"}>{this.getInstructions(props.attr.instructions)}</p>)}
                    <div className="field-value" onClick={(props.attr.editable ? function (e) {
                        _this.updated = false;
                        let offset = document.getSelection().focusOffset;
                        if (offset === undefined) {
                            offset = $(e.target).text().length;
                        }
                        _this.setState({editing: true, focusOffset: offset});
                    } : undefined)}>
                        {value_ || (this.state.value ? Parser(Utils.nl2br(Utils.textWithHyperLinks(stripTags(this.state.value)))) : "")} {props.attr.editable && (<Icon.PencilAlt/>)}</div>
                </label>
            </div>);
        }

        return field;
    }

    selectFactory(props, data) {
        let __this = this;
        let field;
        if (!this.rendered_select && data) {
            let more_results = true;
            let _options = {
                placeholder: T.translate(props.attr.placeholder) || '',
                /*dropdownAutoWidth: true,*/
                multiple: !!props.attr.select.multiple,
                tags: !!props.attr.select.tags,
                minimumResultsForSearch: props.attr.select.preventSearch ? -1 : 0,
                width: '100%',
                ajax: {
                    url: props.attr.select.endpoint,
                    dataType: 'json',
                    delay: 200,
                    data: function (params) {
                        __this.has_createOption = false;
                        return {
                            query: params.term,
                            page: params.page || 0
                        };
                    },
                    processResults: function (data, params) {
                        params.page = params.page || 0;
                        if (params.page === 0 && !more_results)
                            more_results = true;
                        let results = [];
                        data.result.forEach(function (item) {
                            if (item['children']) {
                                results = data.result;
                                return false;
                            }
                            let itext = item[props.attr.select.db_text_field] || item[props.attr.select.text_field];
                            let ival = item[props.attr.select.db_value_field] || item[props.attr.select.value_field];
                            if (_options.tags) {
                                results.push({
                                    id: itext,
                                    text: itext,
                                    data: item
                                });
                            } else {
                                results.push({
                                    id: ival,
                                    text: itext,
                                    data: item
                                });
                            }
                        });

                        if (results.length < 1 || (data.limit && results.length < data.limit)) {
                            more_results = false;
                        }

                        if (props.attr.select.create_option && !__this.has_createOption && !more_results) {
                            results.push({
                                id: "CREATEOPTION",
                                text: T.translate(props.attr.select.create_option.label)
                            });
                            __this.has_createOption = true;
                        }
                        return {
                            results: results,
                            pagination: {more: more_results}
                        };
                    },
                    transport: function (params, success, failure) {
                        Utils.get(params.url, params.data, (data) => {
                            success(data);
                        }, (data) => {
                            failure();
                        });
                    }
                },
            };
            if (props.attr.select.translate) {
                _options["templateResult"] = _options["templateSelection"] = function (item) {
                    if (item.text === '')
                        return '';
                    return T.translate("select-" + item.text);
                };
            } else {
                if (props.attr.select.template_selection)
                    _options["templateSelection"] = this.CASelect2Templates[props.attr.select.template_selection](__this);

                if (props.attr.select.template_result)
                    _options["templateResult"] = this.CASelect2Templates[props.attr.select.template_result];
            }
            let select_data;

            let select_default_value;
            if (data[props.attr.select.db_text_field] || data[props.attr.select.text_field]) {
                if (props.attr.select.multiple) {
                    let aux = [];
                    let values;

                    if (typeof this.state.value === "string") {
                        values = (this.state.value || this.initialValue).split(String.fromCharCode(0x1D));
                    } else {
                        values = this.state.value;
                    }

                    let text = (data[props.attr.select.db_text_field] || data[props.attr.select.text_field]).split(String.fromCharCode(0x1D));
                    let data_fields = props.attr.select.data_fields;
                    if (data_fields) {
                        let data_aux = {};
                        for (let i = 0; i < data_fields.length; i++) {
                            let fields = data[data_fields[i]].split(String.fromCharCode(0x1D));
                            values.forEach(function (value, val_idx) {
                                data_aux[value] = data_aux[value] || {};
                                data_aux[value][data_fields[i]] = fields[val_idx];
                            });
                        }
                        __this.select_extra_data = data_aux;
                    }
                    if (!_options.tags && values) {
                        for (let i = 0; i < values.length; i++) {
                            aux.push({id: values[i], text: text[i], data: text[i]});
                        }
                    } else {
                        aux = text;
                        values = text;
                    }
                    select_data = aux;
                    select_default_value = values;
                } else {
                    select_data = [{
                        text: data[props.attr.select.db_text_field || props.attr.select.text_field].slice(),
                        id: this.state.value || this.initialValue
                    }];
                    select_default_value = this.state.value || this.initialValue;
                }
            }
            let _this = this;
            this.rendered_select = (<Select2 ref="select" multiple={props.attr.select.multiple} defaultValue={select_default_value} options={_options} data={select_data} onChange={(e) => {
                _this.inputChanged(e);
                more_results = true;
            }} onSelect={function (e) {
                if (props.attr.select.data_fields) {
                    let extra_aux = e.params.data;
                    __this.select_extra_data[e.params.data.id] = extra_aux;
                }
            }} onUnselect={function (e) {
                if (props.attr.select.data_fields && __this.select_extra_data && __this.select_extra_data[e.params.data.id]) {
                    delete __this.select_extra_data[e.params.data.id];
                }
                if (__this.props.attr.select.create_option)
                    __this.has_createOption = false;
            }}/>);
        }
        field = (<div className={"Field" + (this.props.attr.editable ? " editable" : "") + (this.state.saving ? " saving" : "")}>
            <label> <T.span text={{key: props.attr.label}}/> {props.attr.required && props.attr.editable && (<span className="required"> *</span>)}{this.state.changed && this.changed_field_icons} {props.attr.instructions && (
                <p className={"instructions"}>{this.getInstructions(props.attr.instructions)}</p>)} {this.rendered_select}
            </label>
        </div>);


        return field;
    }

    CASelect2OptionActions = {
        create_company: function (_this) {
            setTimeout(() => $("input[name='company_name']").focus(), 10);
            confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <div className="container react-confirm-alert-body Form" style={{width: "100%"}}>
                            <h1>{T.translate("label-add_new_company")}</h1>
                            <p style={{"marginBottom": "20px"}}>{T.translate("label-add_new_company_intro")}</p>
                            <label> <span>{T.translate("label-company_name")}</span> <input type="text" name="company_name"/> </label> <label> <span>{T.translate("label-company_url")}</span> <input type="text" name="company_url"/> </label>
                            <div className="react-confirm-alert-button-group">
                                <a className={"Button margin success"} onClick={() => {
                                    let company_name = $("input[name='company_name']").val().trim();
                                    let company_url = $("input[name='company_url']").val().trim();
                                    let params = {
                                        company_name: company_name === "" ? null : company_name,
                                        company_url: company_url === "" ? null : company_url
                                    };

                                    if (params.company_name) {
                                        Utils.post("create_company", params, (data) => {
                                            Utils.success("label-created_company_successfully");
                                            let $sel = $(_this.refs.select.el);
                                            let selectedValues = $sel.val();
                                            $sel.append('<option value="' + data + '">' + company_name + '</option>');
                                            if (selectedValues == null) {
                                                selectedValues = [];
                                            }
                                            selectedValues.push(data);
                                            $sel.val(selectedValues).trigger('change');
                                            onClose();
                                        });
                                    } else {
                                        Utils.error("label-company_name_required", "label-missing_field");
                                    }
                                }}>{T.translate("label-add")}</a> <a className={"Button margin"} onClick={() => {
                                onClose();
                                _this.has_createOption = false;
                            }}>{T.translate("label-cancel")}</a>
                            </div>
                        </div>
                    )
                }
            })
        },
        create_course_request: function (_this) {
            setTimeout(() => $("input[name='course_name']").focus(), 10);
            confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <div className="container react-confirm-alert-body Form" style={{width: "100%"}}>
                            <h1>{T.translate("label-request_course_creation")}</h1>
                            <p style={{"marginBottom": "20px"}}>{T.translate("label-request_course_creation_intro")}</p>
                            <label> <span>{T.translate("label-course_name")}</span> <input type="text" name="course_name"/> </label> <label> <span>{T.translate("label-course_institution")}</span> <input type="text" name="course_institution"/> </label> <label> <span>{T.translate("label-course_type")}</span> <select className={"form-input"} name="course_type">
                            <option selected value={0} disabled>{T.translate("label-select")}</option>
                            <option value={1}>{T.translate("label-bachelor")}</option>
                            <option value={2}>{T.translate("label-master")}</option>
                            <option value={3}>{T.translate("label-phd")}</option>
                        </select> </label>
                            <div className="react-confirm-alert-button-group">
                                <a className={"Button margin success"} onClick={() => {
                                    let course_name = $(".react-confirm-alert-body input[name='course_name']").val().trim();
                                    let course_institution = $(".react-confirm-alert-body input[name='course_institution']").val().trim();
                                    let course_type = $(".react-confirm-alert-body select[name='course_type'] option:selected").val().trim();
                                    let params = {
                                        course_name: course_name === "" ? null : course_name,
                                        course_institution: course_institution === "" ? null : course_institution,
                                        course_type: course_type === "" ? null : course_type
                                    };

                                    if (params.course_name && params.course_institution && params.course_type) {
                                        Utils.post("request_course_creation", params, (data) => {
                                            Utils.success("label-request_course_creation_success");
                                            onClose();
                                        });
                                    } else {
                                        Utils.error("label-inputs_required");
                                    }
                                }}>{T.translate("label-request_creation")}</a> <a className={"Button margin"} onClick={() => {
                                onClose();
                                _this.has_createOption = false;
                            }}>{T.translate("label-cancel")}</a>
                            </div>
                        </div>
                    )
                }
            })
        }
    };

    CASelect2Templates = {
        course_template_selection_alumni: function (_this) {
            return function (course, rel) {
                let data = course.data || _this.select_extra_data[course.id];
                if (!data) return $("<span>Unknown Course</span>");
                let html = '<span style="display: inline-block; font-size: 14px;"><strong style="font-size: 17px;">' + data['course.name'] + '</strong><br>' + (data['organization.name'] || data['course_organization.name']) + " / " + data['course_type.designation'] + '</span>';
                if (rel) {
                    rel.find(".select2-selection__choice__remove").css({top: "-23px", position: 'relative'});
                    rel.css("margin-bottom", "5px");
                }
                return $(html);
            }
        },

        course_template_selection_react: function (course, rel) {
            let data = course.data;
            return (
                <div className={"select_custom_result"}>
                    <span style={Utils.parseStyles("display: inline-block; fontSize: 14px;")}>
                        <strong style={{fontSize: "17px"}}>
                            {data['course.name']}
                        </strong>
                        <br/> {((data['organization.name'] || data['course_organization.name']) + " / " + data['course_type.designation'])}
                    </span>
                </div>);
        },

        course_template_result: function (course) {
            let data = course.data;
            if (!course.id || !data) {
                return course.text;
            }
            let $course = $(
                '<span><strong style="color:rgba(32, 64, 86, 0.7)">' + data['organization.name'] + '</strong><span style="color:rgba(0,0,0,0.24); font-size: 15px;"> ➜ </span><span style="font-size:12px;">(' + data["course_type.designation"] + ')</span> ' + data['course.name'] + '</span>'
            );
            return $course;

        }
    }

    getInstructions(instructions) {
        if (typeof instructions === 'string')
            return Parser(T.translate(instructions));
        else {
            let details = () => {
                Utils.confirm(Parser(T.translate(instructions.long)), instructions.popup_title || "label-more_info", null, "label-close", {wide: true})
            };
            return <span onClick={details} className="clickable">{Parser(T.translate(instructions.short))} <Icon.InfoCircle/></span>;
        }

    }
}

export default Field;