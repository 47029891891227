import React from 'react';
import Utils from "../../utils";
import Elements from "../elements";
import T from 'i18n-react';
import {Icon} from "react-font-awesome-5";
import SearchBar from "../sections/SearchBar";

class Search extends React.Component {
    constructor(props) {
        super(props);

        let _this = this;
        this.onKeyUp = this.onKeyUp.bind(this);
        this.search = this.search.bind(this);
        this.setRef = this.setRef.bind(this);
        this.getMore = this.getMore.bind(this);
        this.applyFilters = this.applyFilters.bind(this);

        this.resultListComponent = (<Elements.SearchResultList ref={(ref) => this.setRef("result_list", ref)} getMore={this.getMore}/>);

        this.searchBar = <SearchBar filters={this.props.config.filters} setFilters={function (filters) {
            _this.filters = filters;
        }} onApplyFilters={this.applyFilters}/>;
    }

    componentDidMount() {
        Utils.getHeader().openHero(<div>
            <h2> {T.translate("label-search")} </h2>
            <span className={"searchInput"}>
                <input autoFocus={true} type="text" onKeyUp={this.onKeyUp} placeholder={T.translate("label-search_input_placeholder")} ref={(ref) => this.setRef("search_input", ref)}/>
                <Icon.Search onClick={this.search}/>
            </span>
        </div>);
        this.search();
    }

    getMore(page, callback) {
        Utils.get(this.props.endpoint, {query: this.search_input.value, filters: this.filters, page: page}, function (data) {
            callback(data);
        });
    }


    setRef(name, ref) {
        this[name] = ref;
    }

    applyFilters(filters) {
        this.filters = filters;
        this.search();
    }

    search() {
        let value = (this.search_input && this.search_input.value) || '';
        let filters = this.filters;
        let _this = this;
        Utils.get(this.props.endpoint, {query: value, filters: filters}, function (data) {
            _this.result_list.setData(data);
        })
    }

    onKeyUp(e) {
        e = e || window.event;

        if (e.key === 'Enter' || e.keyCode === 13) {
            this.search();
        }
    }

    render() {
        return (
            <div className={"Search"}>
                {this.searchBar}
                <div className={"searchResults"}>
                    {this.resultListComponent}
                </div>
                <div style={{clear: "both"}}/>
            </div>

        );
    }
}

export default Search;