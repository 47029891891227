import React from 'react';
import logo from "../../assets/img/logo_simple_white.svg";
import Utils from "../../utils";

class Splashscreen extends React.Component {

    render() {
        let splashBox;
        if (this.props.children)
            splashBox = (<div className={"splashBox animated fadeInUp" + (this.props.boxWide ? " wide" : "")}>
                <div className="container">
                    {this.props.children}
                </div>
            </div>);
        return (
            <div className={"Splashscreen" + (this.props.children ? " withBox" : "")}>
                <div onClick={() => {
                    if (splashBox) {
                        Utils.openUrl("/");
                    }
                }} className={"logo" + (splashBox ? " clickable" : "")} style={{backgroundImage: "url(" + logo + ")"}}/>
                {splashBox}
            </div>
        );
    }
}

export default Splashscreen;