import React from 'react';
import Utils from "../../utils";
import T from 'i18n-react';
import Elements from "../elements";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class HeroActions extends React.Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        let data = props.bundle && props.bundle.data;

        this.checkConditions = function (conditions) {
            let validated = true;
            if (conditions) {
                if (data) {
                    conditions.forEach(function (cond) {
                        if (!validated) return;
                        let cond_value = cond.value ? "'" + cond.value + "'" : null;
                        let cond_data = data[cond.name] ? "'" + data[cond.name] + "'" : null;
                        validated = Utils.evalcode(cond_data + cond.type + cond_value);
                    });
                } else {
                    return false;
                }
            }
            return validated;
        }
    }

    onClick(props) {
        return function (e) {
            let callback = function (params) {
                switch (props.action) {
                    case "post_request":
                        let endpoint = Utils.parseEndpoint(props.endpoint, Utils.getCurrentPage().parameters);
                        Utils.post(endpoint, params);
                        break;
                    case "save_all":
                        Utils.triggerEvent("save_all");
                        break;
                    case "goto":
                        let goto_url = Utils.parseEndpoint(props.endpoint, Utils.getCurrentPage().parameters);
                        Utils.openUrl(goto_url);
                        break;
                    default:
                        break;
                }
            };
            if (props.confirm) {
                confirmAlert({
                    customUI: ({onClose}) => {
                        return (
                            <div className='container react-confirm-alert-body'>
                                <h1>{T.translate('label-confirmation')}</h1>
                                <p>{T.translate(props.confirm)}</p>
                                <div className="react-confirm-alert-button-group">
                                    <a className={"Button margin special"} onClick={() => {
                                        callback();
                                        onClose()
                                    }}>{T.translate("label-yes")}</a> <a className={"Button margin"} onClick={onClose}>{T.translate("label-cancel")}</a>
                                </div>
                            </div>
                        )
                    }
                })
            } else if (props.prompt) {
                Utils.prompt()
            } else {
                callback();
            }

        }
    }

    render() {
        let this_ = this;
        return (<div className={"HeroActions " + this.props.className}>
            {this.props.actions.map(function (action) {
                if (!this_.checkConditions(action.conditions)) {
                    return null;
                }
                return (<a key={Utils.nextKey()} onClick={(e) => e.preventDefault()} href={action.action === 'goto' ? ("/" + Utils.parseEndpoint(action.endpoint, Utils.getCurrentPage().parameters)) : undefined}> <Elements.Button icon={action.icon} label={T.translate(action.label)} onClick={this_.onClick(action)} class={action.type + " margin"}/> </a>)
            })}
        </div>);
    }

}

export default HeroActions;