import React from 'react';
import Utils from "../../utils";
import Elements from "../elements";
import T from 'i18n-react';
import Select2 from "../../other/react-select2-wrapper";
import $ from "jquery";
import Parser from 'html-react-parser';

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValues: {},
            activeStep: null
        };
        this.retrieveBody = this.retrieveBody.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.buildBody = this.buildBody.bind(this);
        this.getField = this.getField.bind(this);
        this.getComponent = this.getComponent.bind(this);

        let attributes = this.props.attr;
        setTimeout(() => this.buildBody(attributes), 0);
        if (this.props.onSuccess)
            this.onSuccess = this.props.onSuccess;

        this._inputValues = {};
    }

    buildBody(attributes) {
        this.inputs = {};
        this.components = [];
        this.steps = {};
        this.submit = null;
        let activeStep = null;
        let this_ = this;
        this.props.attr.body.forEach((e) => {
            if (e.type === 'step') {
                let stepName = e.name;
                let stepAction = e.action;
                if (e.default)
                    activeStep = stepName;
                this.steps[stepName] = {
                    action: stepAction,
                    children: e.children.map((child) => {
                        let field = this.getField(child);
                        if (field)
                            return this_.inputs[child.name] = field;
                        return null;
                    }).filter((e) => e)
                };
            } else {
                this_.inputs[e.name] = this.getField(e);
            }
        });

        this.props.attr.body.forEach((e) => {
            if (e.type === 'step') {
                let stepName = e.name;
                e.children.forEach((child) => {
                    this_.components.push({el: this.getComponent(child), step: stepName});
                });
            } else {
                this_.components.push({el: this.getComponent(e)});
            }
        });

        if (!activeStep) {
            let stepNames = Object.keys(this.steps);
            activeStep = stepNames[0];
        }

        this.setState({activeStep: activeStep, inputValues: this._inputValues});
    }

    getComponent(e) {
        let this_ = this;
        let tokens = e.type.split(":");
        let type = tokens[0];
        switch (type) {
            case "button":
                let button_type = tokens[1] || "";
                let tokens_ = e.action && e.action.split(":");
                let action = tokens_[0];
                let onClick;
                if (action === "submit") {
                    let endpoint = tokens_.length > 1 && tokens_[1];
                    this_.submit = onClick = function (e) {
                        if (this_.props.onSubmit) {
                            this_.props.onSubmit(this_.retrieveBody());
                            return;
                        }
                        if (!this_.submitting) {
                            this_.submitting = true;
                            Utils.post(endpoint, this_.retrieveBody(), function (data) {
                                this_.submitting = false;
                                if (this_.onSuccess)
                                    this_.onSuccess(data);
                            }, function (data) {
                                this_.submitting = false;
                            });
                        }
                    }
                } else if (action === "goto") {
                    onClick = function (e) {
                        window.App.setSplashPage(tokens_[1]);
                    }
                } else if (action === "step" && this_.steps[tokens_[1]]) {
                    onClick = function (e) {
                        this_.setState({activeStep: tokens_[1]})
                    }
                } else if (action === 'special') {
                    onClick = function (e) {
                        this_.props.specialAction && this_.props.specialAction();
                    }
                }
                return (<Elements.Button onClick={onClick} key={Utils.nextKey()} class={"block " + button_type} label={T.translate(e.label)}/>);
            case "text":
                return <p className={"formText"}>{Parser(T.translate(e.label))}</p>;
            default:
                if (tokens[1] === 'checkbox') {
                    return <label key={Utils.nextKey()}><span>{Parser(T.translate(e.label))}</span><br/>
                        <div className={"switch"}>
                            {this_.inputs[e.name]} <span className="slider"/>
                        </div>
                    </label>;
                } else {
                    return (<label key={Utils.nextKey()}><span>{T.translate(e.label)}</span>{this_.inputs[e.name]}</label>);
                }
        }
    }

    getField(e) {
        let this_ = this;
        let tokens = e.type.split(":");
        let type = tokens[0];
        if (e.value) {
            this._inputValues[e.name] = e.value;
        }
        switch (type) {
            case "input":
                let input_type = tokens[1] || "text";
                return (<input type={input_type} defaultValue={e.value} onChange={this_.inputChanged} name={e.name}/>);
            case "select":
                let partial_endpoint = e.endpoint;
                let more_results = true;
                let _options = {
                    placeholder: (e.placeholder && T.translate(e.placeholder)) || '',
                    multiple: !!e.multiple,
                    dropdownCssClass: e.dropdownCssClass,
                    width: '100%',
                    ajax: {
                        url: partial_endpoint,
                        dataType: 'json',
                        delay: 250,
                        data: function (params) {
                            return {
                                query: params.term,
                                page: params.page || 0
                            };
                        },
                        processResults: function (data, params) {
                            params.page = params.page || 0;
                            if (params.page === 0 && !more_results)
                                more_results = true;

                            let results = [];
                            data.result.forEach(function (item) {
                                if (_options.tags)
                                    results.push({
                                        id: item[e.text_field],
                                        text: e.translate_text_field ? T.translate("user_type-" + item[e.text_field]) : item[e.text_field],
                                        data: item
                                    });
                                else
                                    results.push({
                                        id: item[e.value_field],
                                        text: e.translate_text_field ? T.translate("user_type-" + item[e.text_field]) : item[e.text_field],
                                        data: item
                                    });
                            });
                            if (results.length < 1 || (data.limit && results.length < data.limit))
                                more_results = false;
                            return {
                                results: results,
                                pagination: {more: more_results}
                            };
                        },
                        transport: function (params, success, failure) {
                            Utils.get(params.url, params.data, (data) => {
                                console.log(data);
                                success(data);
                            }, (data) => {
                                failure();
                            });
                        }
                    },
                };
                if (e.templateSelection)
                    _options["templateSelection"] = e.templateSelection;

                if (e.templateResult)
                    _options["templateResult"] = e.templateResult;

                e.preventSearch && (_options["minimumResultsForSearch"] = -1);

                return (<Select2/*ref={"select_"+e.name}*/ multiple={e.multiple} options={_options} onChange={(e) => {
                    this_.inputChanged(e);
                }} name={e.name}/>);
            case "checkbox":
                return (<input type="checkbox" name={e.name} onChange={this_.inputChanged} checked={e.value ? "checked" : false}/>);
            default:
                break;
        }
    }

    retrieveBody() {
        let inputValues = this.state.inputValues;
        Object.keys(inputValues).forEach(function (input) {
            if (!inputValues[input]) {
                delete inputValues[input];
            } else if (typeof inputValues[input] === "string") {
                inputValues[input] = inputValues[input].trim();
            }
        });

        this.setState({inputValues: inputValues});
        return inputValues;
    }

    inputChanged(event) {
        let inputValues = this.state.inputValues;
        if (event.target.type.indexOf("select") === 0) {
            inputValues[event.target.name] = $(event.target).val();
        } else if (event.target.type === "checkbox") {
            inputValues[event.target.name] = event.target.checked;
        } else {
            inputValues[event.target.name] = event.target.value;
        }
        this.setState({inputValues: inputValues});
    }


    componentDidMount() {
        window.onkeydown = this.listenForEnter.bind(this);
    }

    listenForEnter(e) {
        e = e || window.event;

        if (this.props.enterSubmit && (e.key === 'Enter' || e.keyCode === 13)) {
            this.submit();
        }
    }

    render() {
        let children = this.components && this.components.map((comp) => {
            if (comp.step) {
                if (this.state.activeStep && this.state.activeStep === comp.step)
                    return comp.el;
                return null;
            }
            return comp.el;
        });

        return (
            <div className="Form">
                {this.props.noBox ?
                    children :
                    <Elements.Box title={T.translate(this.props.attr.title)}>
                        {children}
                    </Elements.Box>}
            </div>
        );
    }
}

export default Form;