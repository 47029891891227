import React from 'react';
import Utils from "../../utils";
import T from "i18n-react/dist/i18n-react";

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }

    componentDidMount() {
        let this_ = this;
        Utils.get(this.props.endpoint, null, function (data) {
            Utils.getHeader().openHero(<h2>{T.translate(this_.props.title)}</h2>);
            this_.setState({data: data});
        }, function (data, status) {
            status === 404 && window.history.back();
        });

    }

    render() {
        let components = (<DoIT bodyTemplate={this.props.template} parent={this} data={this.state.data ? this.state.data : null}
                                endpoint={this.props.endpoint}/>);
        return (
            <div className={"UserProfile " + (this.state.data ? "" : "loading")}>
                {components}
            </div>
        );
    }
}

function DoIT(bundle) {
    return Utils.recursiveDraw(bundle.bodyTemplate, bundle);
}

export default UserProfile;