import React from "react";
import Utils from "../../utils";
import MasonryInfiniteScroller from "react-masonry-infinite";

export default class UserDocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            hasMore: true
        };
        this.count = 0;
        this.loading = false;
        this.loadMore = this.loadMore.bind(this);
        //Utils.get()
    }

    componentDidMount() {
        let _this = this;
        if (!this.props.bundle.data[this.props.attr.name])
            return;
        let endpoint = "user_documents/" + this.props.bundle.data[this.props.attr.name];
        Utils.get(endpoint, null, function (data) {
            _this.setState({data: data.result});
            _this.refs.masonry.forcePack();
        }, function (data, status) {
            status === 404 && window.history.back();
        });
        this.oldwindowresize = window.onresize;
        window.onresize = function () {
            _this.refs.masonry.forcePack();
        };
    }

    componentWillUnmount() {
        window.onresize = this.oldwindowresize;
    }

    loadMore(page) {
        if (this.loading)
            return;
        this.loading = true;
        let _this = this;
        if (!this.props.bundle.data[this.props.attr.name])
            return;
        let endpoint = "user_documents/" + this.props.bundle.data[this.props.attr.name];
        Utils.get(endpoint, {page: page}, function (data) {
            _this.loading = false;
            _this.setState({data: _this.state.data.concat(data.result), hasMore: data.result.length > 0});
            _this.refs.masonry.forcePack();
        });
    }

    render() {
        let sizes = [
            {columns: 1, gutter: 10},
            {mq: '650px', columns: 2, gutter: 10},
            {mq: '1024px', columns: 3, gutter: 10}
        ];
        return (
            <div className={"List " + (this.state.data ? "" : "loading")}>
                {this.state.data ?
                    (<MasonryInfiniteScroller key={Utils.nextKey()} ref="masonry" sizes={sizes} hasMore={this.state.hasMore} loadMore={this.loadMore} packed={"packed-list"} loader={
                        <div className="sk-folding-cube">
                            <div className="sk-cube1 sk-cube"/>
                            <div className="sk-cube2 sk-cube"/>
                            <div className="sk-cube4 sk-cube"/>
                            <div className="sk-cube3 sk-cube"/>
                        </div>
                    }>
                        {this.state.data.map(item => {
                                let template = Utils.getTemplate(item['document_type.designation'].toLowerCase(), "list");
                                return Utils.recursiveDraw(template, {data: item, custom: {hide_user: true}}, ++this.count)
                            }
                        )}
                    </MasonryInfiniteScroller>) : undefined}
            </div>
        );
    }
}