import Utils from "../../utils";
import {Icon} from "react-font-awesome-5";
import React from "react";
import T from "i18n-react";
import moment from "moment";
import Moment from "react-moment";
import ReactTooltip from "react-tooltip";
import Parser from "html-react-parser";
import HeroActions from "../sections/HeroActions";
import Elements from "./";

export default function (props) {
    let header_type = props.attr._header_type;
    let data = props.bundle && props.bundle.data;

    data && Utils.registerEvent("ca_field_trigger-document.title",
        function (event, data) {
            setHeaderTitle(data.value);
        }, this);

    if (!data)
        data = {};
    let user_fullname;
    if (data['user.name'] && data['user.surname'])
        user_fullname = data['user.name'].trim().split(" ")[0] + " " + data['user.surname'].trim().split(" ").reverse()[0];

    function setHeaderTitle(new_title) {
        let header_children = null;
        switch (header_type) {
            case "Proposal":
            case "Challenge":
                let RelIcon = Icon[data['document.published'] ? (data['document.closed'] ? "CalendarTimes" : "Globe") : "PencilAlt"];
                header_children = (
                    <React.Fragment>
                        <h2>
                            <span data-tip={T.translate("label-" + (data['document.published'] ? (!data['document.closed'] ? "published" : "closed") : "draft"))}><RelIcon/> </span> {new_title || data['document.title']}
                        </h2>
                        <h3 style={{marginTop: 10}}>
                            {data['view_count'] > 0 && (<span><span style={{minWidth: 25, display: "inline-block", textAlign: "center"}}><Icon.Eye/></span> {data['view_count']} {T.translate("label-view" + (data['view_count'] !== '1' ? "s" : ""))}<br/></span>)} {data['interest_count'] > 0 && (<span className="clickable-light" onClick={() => {
                            Utils.get(props.bundle.endpoint + "/interested", null, (data) => {
                                let users = [];
                                data.forEach((interested_user) => {
                                    let user_fullname = interested_user['user.name'].trim().split(" ")[0] + " " + interested_user['user.surname'].trim().split(" ").reverse()[0];
                                    users.push(<Elements.UserBox fullWidth newPage name={user_fullname} user_id={interested_user['user.id']} use_dark_text={true} organization={interested_user["organization.name"]} image={"static/image/user/" + interested_user['user.image']}/>);
                                });
                                Utils.confirm(users, "label-interested_users_title", null, "label-close");
                                console.log(users);
                            });
                        }}><span style={{minWidth: 25, display: "inline-block", textAlign: "center"}}><Icon.Comments/></span> {data['interest_count']} {T.translate("label-interested_user" + (data['interest_count'] !== '1' ? "s" : ""))} <br/></span>)} {data['challenge.deadline'] && data['template'] === 'public' && (
                            <span data-tip={T.translate("label-deadline") + ": " + moment(data['challenge.deadline']).format("YYYY/MM/DD HH:mm")}><span style={{minWidth: 25, display: "inline-block", textAlign: "center"}}><Icon.Stopwatch/></span> <Moment fromNow={true} date={new Date(data['challenge.deadline'])} interval={1000}/></span>)}
                        </h3>
                        <ReactTooltip effect="float" place="bottom"/> {/*data['template'] === 'public' && <Elements.UserBox name={user_fullname} user_id={data['user.id']} organization={data['organization.name']} image={"static/image/user/" + data['user.image']}/>*/}
                    </React.Fragment>
                );
                break;
            case "User":
                /*let professional_company = data['user_professional.job_title'] || "";
                professional_company += (professional_company !== '' && data['organization.name'] ? " @ " : "") + (data['organization.name'] || '');*/

                if (data)
                    header_children = (
                        <div className={"UserHeader"}>
                            <Elements.Image alt={user_fullname} style={Utils.parseStyles("width: 120px; height: 120px; marginBottom: 0; padding: 0")} src={"static/image/user/" + data['user.image'] + "_m"} default={'static/image/user/no_image'} use_background="cover"/>
                            <div>
        <span className="center">
        <h1>{user_fullname}</h1>{data['user_type.designation'] && (<p><Icon.User/> {T.translate("label-" + (data['user_type.designation']).toLowerCase())}</p>)} {data['country.name'] && (<p><Icon.Globe/> {data['country.name']}</p>)} {/*data['user_type.designation'] === 'Professional' && (<p><Icon.Briefcase/> {professional_company}</p>)*/}
        </span>
                            </div>

                        </div>
                    );
                break;
            case "Generic":
                header_children = (<div><h2>{T.translate(props.bundle.parent.props.title)}</h2>{props.bundle.data['header_extra_text'] && (<p className={"headerExtraText"}>{Parser(props.bundle.data['header_extra_text'])}</p>)}
                </div>);
                break;
            default:
                header_children = (<div>Header type missing</div>);
                break;
        }
        let header = (<div>{header_children}</div>);
        let page = props.bundle.page ? props.bundle.page : Utils.getCurrentPage().page;
        if (page && page.header_actions)
            header = (<div>
                <div className={"float-left"}>{header_children}</div>
                <HeroActions bundle={props.bundle} actions={page.header_actions}/>
                <div style={{clear: "both"}}></div>
            </div>);

        Utils.getHeader().openHero(header);
    }

    setHeaderTitle();
    return null;
}